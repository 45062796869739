import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ReportModel } from '@/models/report';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { DimensionModel } from '@/models/dimension';
import { MeasureModel } from '@/models/measure';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamList } from '@/collections/params';
import { ComparisonDateModel } from '@/models/comparison_date';
import { MetricList } from '@/collections/metrics';
import { ProductSegmentModel } from '@/models/product_segment';

@Component({})
export default class Report extends Vue {
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({ default: () => new DimensionModel() })
  public dimension!: DimensionModel;
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  @Prop({ default: () => [] })
  public filterTimerange!: string[];
  @Prop({ default: false })
  public isDetail!: boolean;
  @Prop({ default: () => new FilterDimensionList() })
  public filterDimensions!: FilterDimensionList;
  public typeReport: string = 'SUMMARY';
  public report: ReportModel = new ReportModel();
  public isLoading: boolean = false;
  public isCreated: boolean = false;
  public page!: any;
  public perPage!: any;
  public errorData: boolean = false;
  public needCount: boolean = false;
  public sortBy!: any;
  public sortDesc!: any;
  @Prop({ default: () => new FilterColumnList() })
  public filterColumns!: FilterColumnList;
  @Prop({ default: () => new ParamList() })
  public reportParams!: ParamList;
  @Prop({default: false})
  public isResetFilterColumns!: boolean;
  @Prop({default: '_rows_'})
  public pivotOption!: string;
  @Prop( {default: false})
  public needRefreshData!: boolean;
  @Prop( {default: () => []})
  public reportColumns: any;
  @Prop({default: () => new ComparisonDateModel()})
  public comparisonDate!: ComparisonDateModel;
  public error: any;
  @Prop({default: () => new MetricList()})
  public calculatedMetrics!: MetricList;
  @Prop({default: () => new ProductSegmentModel()})
  public productSegment!: ProductSegmentModel;
  @Prop({ default: false})
  public noLoadSegmentData!: boolean;
  @Prop({default: true})
  public readyToGetReport!: boolean;
  public async created() {
    this.report.typeReport = this.typeReport;
    this.report.isDetail = this.isDetail;
  }

  /**
   * reload all report
   */
  public async getReport() {
    if (!this.readyToGetReport) {
      return;
    }
    if (this.isLoading) {
      return;
    }
    if (!this.measure || !this.measure.code) {
      return;
    }
    if (!this.dimension || !this.dimension.code) {
      return;
    }

    this.report = new ReportModel();
    this.errorData = false;
    this.resetOptions();
    try {
      if (this.needCount) {
        this.getCount();
      }
    } catch (e: any) {
      // do nothing
    }
    try {
      await this.getData();
      this.isCreated = true;
    } catch (e: any) {
      if (e.code !== 'ERR_CANCELED') {
        this.errorData = true;
        this.$emit('error', e);
        this.isCreated = false;
      }
    }
    this.$emit('refresh-data-completed');
  }

  /**
   * update data only
   */
  public async getData() {
    try {
      this.isLoading = true;
      this.report.measure = this.measure;
      this.report.dimension = this.dimension;
      this.report.analyticsType = this.analyticsType;
      this.report.filterTimerange = this.filterTimerange;
      this.report.isDetail = this.isDetail;
      this.report.filterDimensions = this.filterDimensions;
      this.report.filterColumns = this.filterColumns;
      this.report.reportParams = this.reportParams;
      this.report.reportColumns = this.reportColumns;
      this.report.comparisonDate = this.comparisonDate;
      this.report.typeReport = this.typeReport;
      this.report.calculatedMetrics = this.calculatedMetrics;
      if (!this.noLoadSegmentData) {
        this.report.productSegment = this.productSegment;
      }
      if (this.page && this.perPage) {
        this.report.page = this.page;
        this.report.perPage = this.perPage;
      }
      if (this.sortBy || this.report.sortBy) {
        this.report.sortBy = this.sortBy;
        this.report.sortDesc = this.sortDesc;
      }
      this.report.pivotOption = this.pivotOption;
      await this.report.getReport(!this.needRefreshData);
      this.reload();
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      if (error.code !== 'ERR_CANCELED') {
        this.$emit('error', error);
        this.errorData = true;
      }
    }
  }

  public async getCount() {
    // this.errorData = false;
    try {
      await this.getCountData();
    } catch (e: any) {
      if (e.code !== 'ERR_CANCELED') {
        // this.errorData = true;
      }
    }
  }

  public async getCountData() {
    try {
      // this.errorData = false;
      this.report.measure = this.measure;
      this.report.dimension = this.dimension;
      this.report.analyticsType = this.analyticsType;
      this.report.filterTimerange = this.filterTimerange;
      this.report.isDetail = this.isDetail;
      this.report.filterDimensions = this.filterDimensions;
      this.report.filterColumns = this.filterColumns;
      this.report.reportParams = this.reportParams;
      if (!this.noLoadSegmentData) {
        this.report.productSegment = this.productSegment;
      }
      if (this.page && this.perPage) {
        this.report.page = this.page;
        this.report.perPage = this.perPage;
      }
      if (this.sortBy || this.report.sortBy) {
        this.report.sortBy = this.sortBy;
        this.report.sortDesc = this.sortDesc;
      }
      this.report.pivotOption = this.pivotOption;
      await this.report.getCount(!this.needRefreshData);
    } catch (e: any) {
      if (e.code !== 'ERR_CANCELED') {
        this.$emit('error', e);
        // this.errorData = true;
      }
    }
  }

  public reload() {
    // rebuild report
  }

  public resetOptions() {
    // reset options
  }

  public resetSortOptions() {
    // reset options
  }

  @Watch('filterTimerange', { immediate: true, deep: true  })
  public async onFilterTimerangeChanged(newVal: any, oldVal: any) {
    if (this.filterTimerange.length < 2) {
      return;
    }
    if (oldVal && newVal && this.filterTimerange.length === oldVal.length
      && this.filterTimerange[0] === oldVal[0]
      &&  this.filterTimerange[1] === oldVal[1]
      && this.filterTimerange[2] === oldVal[2]
      ) {
        return;
      }
    await this.getReport();
  }

  @Watch('comparisonDate', { immediate: true, deep: true  })
  private async onComparisonDateChanged(newVal: any) {
    if (this.isCreated) {
      await this.getReport();
    }
  }

  @Watch('analyticsType', { immediate: true, deep: true  })
  private async onAnalyticsTypeChanged(newVal: any) {
    if (this.isCreated) {
      this.resetSortOptions();
      await this.getReport();
    }
  }

  @Watch('filterDimensions', { immediate: true, deep: true  })
  private async onFilterDimentionsChanged(newVal: any) {
    if (this.isCreated) {
      await this.getReport();
    }
  }

  @Watch('filterColumns', { immediate: true, deep: true  })
  private async onFilterColumnsChanged(newVal: any) {
    if (this.isCreated) {
      if (this.isResetFilterColumns) {
        this.isResetFilterColumns = false;
      } else {
        await this.getReport();
      }
    }
  }

  @Watch('reportParams', { immediate: true, deep: true  })
  private async onReportParamsChanged(newVal: any) {
    if (this.isCreated) {
      await this.getReport();
    }
  }

  @Watch('needRefreshData', { immediate: true, deep: true })
  private async onNeedRefreshDataChange(newVal: any) {
    if (newVal && newVal === true) {
      await this.getReport();
    }
  }

  @Watch('dimension', { immediate: true, deep: true })
  private async onDimensionChange(newVal: any) {
    if (this.isCreated) {
      if (!this.dimension.params || this.dimension.params.size() === 0) {
        await this.getReport();
      } else {
        let hasDefautl: boolean = false;
        for (const item of this.dimension.params.items) {
          if (item.value && item.value.length > 0) {
            hasDefautl = true;
            break;
          }
        }
        if (!hasDefautl) {
          await this.getReport();
        }
      }
    }
  }

  @Watch('productSegment', { immediate: true, deep: true })
  private async onProductSegmentChange(newVal: any) {
    if (this.isCreated) {
      await this.getReport();
    }
  }
  @Watch('readyToGetReport', { immediate: true, deep: true })
  private async onReadyToGetReportChange(newVal: any) {
    if (this.readyToGetReport) {
      await this.getReport();
    }
  }
}
