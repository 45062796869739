
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventHandler } from '@/modules/events';
import HeaderTitle from '../components/HeaderTitle.vue';
import ChatWithUs from '../components/ChatWithUs.vue';
import { ProductSegmentModel } from '@/models/product_segment';
import { ProductSegmentList } from '@/collections/product_segments';
import { MeasureList } from '@/collections/measures';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import { StoreModel } from '@/models/store';
import { RuleModel } from '@/models/rule';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import ActionButton from '@/components/ActionButton.vue';
import EmptyStage from '@/components/EmptyStage.vue';
import ProductSegmentDialog from '@/components/ProductSegmentDialog.vue';
import { EventBus } from '@/main';
import VideoGuides from "@/components/VideoGuides.vue";

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
    ContentLoadResultMessage,
    FeatureLockedNotify,
    ActionButton,
    EmptyStage,
    ProductSegmentDialog,
    VideoGuides,
  },
})
export default class ProdudctSegmentListing extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public productSegments: ProductSegmentList = new ProductSegmentList();
  public deletingProductSegment: ProductSegmentModel = new ProductSegmentModel();
  public deletingIndex: number = -1;
  public dialogDelete: boolean = false;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public headers: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public rule: RuleModel = new RuleModel({ code: 'schedule_reports_count' });
  public productSegmentTemplates: any = [];
  public videoGuideLinks: any = [
  {
      title: 'How to Create and Manage Product Segment',
      link: 'https://www.youtube.com/embed/nq53Fjp4kxM?si=BaLIy1izpiIKu_Xk',
    },
  ];
  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view notification page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
        break;
      }
    }
    this.headers = [
      {
        text: 'Segment Name',
        value: 'name',
        width: '25%',
      },
      {
        text: 'Type',
        value: 'type',
      },
      {
        text: 'Product Count',
        value: 'count',
      },
      {
        text: 'Description',
        value: 'description',
      },
      {
        text: 'Action',
        value: 'action',
        width: '10%',
        align: 'center',
      },
    ];
    await this.fullLoadData();
    this.isLoading = false;
  }

  public showCreateProductSegmentDialog() {
    EventBus.$emit('show-create-product-segment-dialog');
  }
  public async fullLoadData() {
    this.isLoading = true;
    this.productSegments = new ProductSegmentList();
    try {
      await this.productSegments.fetch();
    } catch (e) {
      // ignore this
      EventBus.$emit('show-snackbar', {
        message: 'Failed to load product segments',
        color: 'error',
      });
    }
    this.isLoading = false;
  }

  public editSegment(productSegment: ProductSegmentModel) {
    this.$router.push(`/product-segment/${productSegment.id}`);
  }

  public confirmDelete(productSegment: ProductSegmentModel, index: number) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`click delete notification`);
    } catch (e) {
      // ignore this
    }
    this.deletingIndex = index;
    this.deletingProductSegment = productSegment;
    this.dialogDelete = true;
  }

  public async deleteItem(productSegment: ProductSegmentModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete product segment`, {});
      await this.deletingProductSegment.remove();
      this.productSegments.items.splice(this.deletingIndex, 1);
      EventBus.$emit('show-snackbar', 'Product Segment deleted successfully', 'success');
    } catch (e) {
      // ignore this
      EventBus.$emit('show-snackbar', {
        message: 'Failed to delete product segment',
        color: 'error',
      });
    }
    this.deletingIndex = -1;
    this.dialogDelete = false;
  }

  public upperCaseFirstLetter(str: string) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  public initProductSegmentTemplate() {
    this.productSegmentTemplates = [
      {
        name: 'Quick-Sell Inventory',
        description: 'For high turnover products that sell quickly and need frequent replenishment.',
        measure: '',
        params: [

        ],
      },
    ];
  }
}
