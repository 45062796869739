
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { StoreModel } from "@/models/store";
import { MeasureModel } from "@/models/measure";
import { DimensionModel } from "@/models/dimension";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { ProductSegmentModel } from "@/models/product_segment";
import { ComparisonDateModel } from "@/models/comparison_date";
import { FilterColumnList } from "@/collections/filter_columns";
import { ParamList } from "@/collections/params";
import FilterColumn from "@/components/FilterColumn.vue";
import ReportParam from "@/components/ReportParam.vue";
import DateFilter from "@/components/DateFilter.vue";
import ComparisonDate from "@/components/ComparisonDate.vue";
import GroupByDimension from "@/components/GroupByDimension.vue";
import AnalyticsTypeFilter from "@/components/AnalyticsTypeFilter.vue";
import { MeasureList } from "@/collections/measures";
import ActionButton from "@/components/ActionButton.vue";
import SegmentSelector from "@/components/SegmentSelector.vue";
import { DimensionList } from '@/collections/dimensions';

@Component({
  components: {
    FilterColumn,
    ReportParam,
    DateFilter,
    GroupByDimension,
    AnalyticsTypeFilter,
    ActionButton,
    ComparisonDate,
    SegmentSelector,
  },
})
export default class ReportFilter extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({ default: () => new DimensionModel() })
  public initDimension!: DimensionModel;
  @Prop({ default: () => new DimensionList() })
  public dimensions!: DimensionList;
  public dimension: DimensionModel = new DimensionModel();
  @Prop({ default: () => new AnalyticsTypeModel() })
  public initAnalyticsType!: AnalyticsTypeModel;
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public dates: string[] = [];
  @Prop({ default: "30d" })
  public initRange!: string;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public comparisonDate: ComparisonDateModel = new ComparisonDateModel();
  @Prop({ default: () => new ComparisonDateModel() })
  public initComparisonDate!: ComparisonDateModel;
  public filterColumns: FilterColumnList = new FilterColumnList();
  @Prop({ default: () => new FilterColumnList() })
  public initFilterColumns!: FilterColumnList;
  public reportParams: ParamList = new ParamList();
  @Prop({ default: () => new ParamList() })
  public initReportParams!: ParamList;
  @Prop({ default: () => [] })
  public reportColumns!: any;
  @Prop({ default: () => new ParamList() })
  public availableParams!: ParamList;
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  @Prop({ default: () => new ProductSegmentModel() })
  public initProductSegment!: ProductSegmentModel;
  public filterDialog: boolean = false;
  @Prop({ default: false })
  public isPrimaryButton!: boolean;
  @Prop({ default: "Filter" })
  public buttonTitle!: string;
  @Prop({ default: "mdi-filter" })
  public buttonIcon!: string;
  @Prop({ default: "small" })
  public buttonSize!: string;
  @Prop({ default: false })
  public showAnalyticsType!: boolean;
  @Prop({ default: true })
  public showDateFilter!: boolean;
  @Prop({ default: true })
  public showGroupByDimension!: boolean;
  @Prop({ default: true })
  public showReportParam!: boolean;
  public async created() {

  }

  public async mounted() {
    this.dates = this.timeRangeLimit;
    this.filterColumns = this.initFilterColumns;
    this.reportParams = this.initReportParams;
    this.dimension = this.initDimension;
    this.analyticsType = this.initAnalyticsType;
  }

  public get getButtonTitle() {
    if (this.filterColumns.items.length > 0) {
      return `${this.buttonTitle} (${this.filterColumns.items.length} active)`;
    }
    return this.buttonTitle;
  }
  public applyFilter() {
    this.$emit("input", {
      dates: this.dates,
      filterColumns: this.filterColumns,
      reportParams: this.reportParams,
      dimension: this.dimension,
      analyticsType: this.analyticsType,
      productSegment: this.productSegment,
    });
    this.filterDialog = false;
  }

  //Watch initFilterColumns if it changed then update filterColumns
  @Watch("initFilterColumns")
  private onInitFilterColumnsChanged(newVal: any, oldVal: any) {
    this.filterColumns = this.initFilterColumns;
  }
}
