
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ComparisonDateModel } from '@/models/comparison_date';
import { ComparisonDateList } from '@/collections/comparison_date';
import dateFormat from 'dateformat';

@Component({})
export default class ComparisonDate extends Vue {
  @Prop({ default: null })
  public value!: ComparisonDateModel | null;

  public comparisonDates: ComparisonDateList = new ComparisonDateList();
  public comparisonDate: ComparisonDateModel = new ComparisonDateModel();
  public comparisonDateName: string = 'None';
  public comparisonDateMenu: boolean = false;
  public keyword: string = '';
  @Prop({ default: true })
  public outlined!: boolean;
  public initComparisonDates: any = [
    {name: 'None', code: ''},
    {name: 'Last Period', code: 'last_period'},
    {name: 'Last Week', code: 'last_week'},
    {name: 'Last Month', code: 'last_month'},
    {name: 'Last Quarter', code: 'last_quarter'},
    {name: 'Last Year', code: 'last_year'},
  ];

  public created() {
    for (const item of this.initComparisonDates) {
      const period = new ComparisonDateModel();
      period.mapData(item);
      this.comparisonDates.add(period);
    }
    this.comparisonDate = this.comparisonDates.items[0];
  }

  public selectComparisonDate() {
    this.$emit('input', this.comparisonDate);
  }
}
