
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { MeasureList } from "@/collections/measures";
import { StoreModel } from "@/models/store";
import HeaderTitle from "./HeaderTitle.vue";
import { DashboardModel } from "@/models/dashboard";
import ReportCard from "./ReportCard.vue";
import DateFilter from "@/components/DateFilter.vue";
import { EventHandler } from "@/modules/events";
import { Container, Draggable } from "vue-smooth-dnd";
import { isShopifyEmbedded } from "@shopify/app-bridge/utilities";
import ActionButton from "@/components/ActionButton.vue";
import ComparisonDate from "@/components/ComparisonDate.vue";
import { ComparisonDateModel } from "@/models/comparison_date";
import { ReportCardModel } from "@/models/report_card";
import { ProductSegmentModel } from "@/models/product_segment";
import FilterColumn from "@/components/FilterColumn.vue";
import { FilterColumnList } from "@/collections/filter_columns";
import SegmentSelector from "@/components/SegmentSelector.vue";
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
    ReportCard,
    DateFilter,
    Container,
    Draggable,
    ActionButton,
    ComparisonDate,
    FilterColumn,
    SegmentSelector,
  },
})
export default class Dashboard extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: "available" })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public loading: boolean = false;
  public dashboardModel: DashboardModel = new DashboardModel();
  public dates: any = [];
  public defaultRange: string = "";
  public cardDefaultRange: string = "7d";
  public useDefaultConfig: boolean = true;
  public showFilter: boolean = false;
  public dashboardMenu: boolean = true;
  public isCreated: boolean = false;
  public dialogRenameDashboard: boolean = false;
  public dialogDeleteDashboard: boolean = false;
  public newDashboardName: string = "";
  public renameDashboardError: boolean = false;
  public needReload: any = [];
  public publishDashboardLoading: boolean = false;
  public isPublicDashboard: boolean = true;
  public canPublishDashboard: boolean = false;
  public isSystemDashboard: boolean = false;
  public publishSystemLoading: boolean = false;
  public updateCardTimerangeLoading: boolean = false;
  public dialogUpdateCardTimerange: boolean = false;
  public dialogUpdateCardPosition: boolean = false;
  public isMovingCard: boolean = false;
  @Prop({ default: "SYSTEM" })
  public type!: string;
  @Prop({ default: 0 })
  public dashboardId!: number;
  @Prop({ default: false })
  public isHome!: boolean;
  public timeranges: any = [
    { value: "today", text: "Today" },
    { value: "yesterday", text: "Yesterday" },
    { value: "7d", text: "Last 7 days" },
    { value: "14d", text: "Last 14 days" },
    { value: "30d", text: "Last 30 days" },
    { value: "3m", text: "Last 3 months" },
    { value: "6m", text: "Last 6 months" },
    { value: "1y", text: "Last 365 days" },
    { value: "week-to-date", text: "Week to date" },
    { value: "month-to-date", text: "Month to date" },
    { value: "year-to-date", text: "Year to date" },
    { value: "last-week", text: "Last week" },
    { value: "last-month", text: "Last month" },
    { value: "last-year", text: "Last year" },
  ];
  public comparisonDateModel: ComparisonDateModel = new ComparisonDateModel();
  public showSetting: boolean = false;
  public setHomeDashboardLoading: boolean = false;
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public filterColumns: FilterColumnList = new FilterColumnList();
  public filters: any = [];
  public lockBoard: any = [];
  public saveLoading: boolean = false;
  public async created() {
    

    this.getLockBoardInfo();
    this.canPublishDashboard = this.checkPublishDashboardPermision();
    this.initFilters();
    await this.fullLoad();
    this.isCreated = true;
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track("View Dashboard", {
        dashboardName: this.dashboardModel.name,
        dashboardId: this.dashboardModel.id,
      });
    } catch (e) {
      // ignore this
    }

    
  }
  public getLockBoardInfo() {
    const dashboardId = this.$route.params.dashboard_id || this.dashboardId;
    const dashboardModel: DashboardModel = new DashboardModel();
    this.lockBoard = dashboardModel.getLockboard(Number(dashboardId));
    return this.lockBoard;
  }

  public get canViewBoard() {
    if (this.store.currentPackage.name !== "Free") {
      return true;
    }
    if (!this.lockBoard.id) {
      return true;
    } else {
      return !this.lockBoard.isLock;
    }
  }

  public initFilters() {
    this.filters = [
      {
        name: "Product",
        code: "Item[Item Description]",
        dataType: "text",
        luisMapping: "by_product",
      },
      {
        name: "Product status",
        code: "product_status",
        dataType: "text",
        luisMapping: "by_product_status",
      },
      {
        name: "Variant",
        code: "variant",
        dataType: "text",
        luisMapping: null,
      },
      {
        name: "Collections",
        code: "product_collections",
        dataType: "text",
        luisMapping: "product_collections",
      },
      {
        name: "Product Type",
        code: "product_type",
        dataType: "text",
        luisMapping: "by_product_type",
      },
      {
        name: "Store Location",
        code: "Location[Location]",
        dataType: "text",
        luisMapping: "by_location",
      },
      {
        name: "Vendor",
        code: "Vendor Name",
        dataType: "text",
        luisMapping: "by_vendor",
      },
      {
        name: "Product Tags",
        code: "product_tags",
        dataType: "text",
        luisMapping: "product_tags",
      },
      {
        name: "SKU",
        code: "product_sku",
        dataType: "text",
        luisMapping: "by_product_sku",
      },
      {
        name: "Barcode",
        code: "product_barcode",
        dataType: "text",
        luisMapping: "by_product_barcode",
      },
    ];
  }
  public get isPrivateDashboard() {
    if (this.dashboardModel.type === "PRIVATE" || this.canPublishDashboard) {
      return true;
    }
    return false;
  }
  public async setAsHomepage() {
    this.setHomeDashboardLoading = true;
    this.store.config.homepageDashboardId = this.dashboardModel.id;
    this.store.config.homepageDashboardType = this.dashboardModel.type;
    try {
      await this.store.saveSettings();
      EventBus.$emit("show-snackbar", {
        message: "Dashboard has been set as homepage",
      });
    } catch (e) {
      EventBus.$emit("show-snackbar", {
        message: "Failed to set dashboard as homepage",
        color: "error",
      });
    }
    this.setHomeDashboardLoading = false;
  }

  public async onDrop(result: any) {
    if (this.isMovingCard) {
      return;
    }
    this.isMovingCard = true;
    this.dashboardModel.reportCardList.items = this.applyDrag(
      this.dashboardModel.reportCardList.items,
      result
    );
    await this.dashboardModel.updateSort();
    this.isMovingCard = false;
  }

  public applyDrag(result: any, dragResult: any) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return result;
    }
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  }

  public getCardFilterTimerange(reportCard: ReportCardModel) {
    if (this.useDefaultConfig) {
      return reportCard.filterTimerange;
    } else {
      return this.dates;
    }
  }

  public getFilterColumns(reportCard: ReportCardModel) {
    if (this.useDefaultConfig) {
      return reportCard.filterColumns;
    } else {
      return this.filterColumns;
    }
  }

  public getComparisonDate(reportCard: ReportCardModel) {
    if (this.useDefaultConfig) {
      return reportCard.comparisonDate;
    } else {
      return this.comparisonDateModel;
    }
  }

  public getProductSegment(reportCard: ReportCardModel) {
    if (this.useDefaultConfig) {
      return new ProductSegmentModel({ id: reportCard.productSegmentId });
    } else {
      return this.productSegment;
    }
  }

  public async duplicateDashboard() {
    try {
      await this.dashboardModel.copy();
      EventBus.$emit("show-snackbar", {
        message: "Dashboard has been duplicated",
      });
      this.$router.push("/myboard/" + this.dashboardModel.id);
    } catch (e) {
      EventBus.$emit("show-snackbar", {
        message: "Failed to duplicate dashboard",
        color: "error",
      });
    }
  }

  public printDashboard() {
    window.print();
  }

  public get getTimerangeText() {
    for (const item of this.timeranges) {
      if (this.cardDefaultRange === item.value) {
        return item.text;
      }
    }
    return "";
  }

  public async updateCardTimerange() {
    this.updateCardTimerangeLoading = true;
    this.dialogUpdateCardTimerange = false;
    try {
      for (const card of this.dashboardModel.reportCardList.items) {
        card.defaultRange = this.cardDefaultRange;
        await card.update();
      }
      EventBus.$emit("show-snackbar", {
        message: "Time range has been updated",
      });
    } catch (e: any) {
      this.updateCardTimerangeLoading = false;
      EventBus.$emit("show-snackbar", {
        message: "Failed to update time range",
        color: "error",
      });
    }
    this.updateCardTimerangeLoading = false;
  }
  public checkPublishDashboardPermision() {
    const shopifyStore = localStorage.getItem("shopify_store");
    if (shopifyStore === "dariustest2.myshopify.com") {
      return true;
    }
    return false;
  }

  public async fullLoad() {
    this.loading = true;
    const dashboardId = this.$route.params.dashboard_id || this.dashboardId;
    let type = this.$route.params.type || this.type;
    type = type.toUpperCase();
    this.dashboardModel = new DashboardModel({ id: dashboardId });
    try {
      if (type === "PUBLIC") {
        await this.dashboardModel.fetchPublic();
      } else if (type === "SYSTEM") {
        await this.dashboardModel.fetchSystem();
      } else {
        await this.dashboardModel.fetch();
      }
    } catch (e: any) {
      await this.dashboardModel.fetch();
      EventBus.$emit("show-snackbar", {
        message: "Failed to load dashboard",
        color: "error",
      });
    }

    this.isPublicDashboard =
      this.dashboardModel.type === "PUBLIC" ? true : false;
    this.isSystemDashboard =
      this.dashboardModel.type === "SYSTEM" ? true : false;
    this.newDashboardName = this.dashboardModel.name;
    this.loading = false;
    if (!this.dashboardModel.id || this.dashboardModel.id === undefined) {
      this.$router.push("/");
    }
    this.setReloadAllCard(false);
  }

  public async publishDashboard() {
    try {
      this.publishDashboardLoading = true;
      await this.dashboardModel.publishDashboard();
      this.publishDashboardLoading = false;
      EventBus.$emit("show-snackbar", {
        message: "Dashboard has been published",
      });
    } catch (e) {
      this.publishDashboardLoading = false;
      EventBus.$emit("show-snackbar", {
        message: "Failed to publish dashboard",
        color: "error",
      });
    }
    this.isPublicDashboard =
      this.dashboardModel.type === "PUBLIC" ? true : false;
    localStorage.setItem("needReloadDashboard", "true");
  }

  public async publishSystem() {
    try {
      this.publishSystemLoading = true;
      await this.dashboardModel.publishSystem();
      this.publishSystemLoading = false;
      EventBus.$emit("show-snackbar", {
        message: "Dashboard has been published",
      });
    } catch (e) {
      this.publishSystemLoading = false;
      EventBus.$emit("show-snackbar", {
        message: "Failed to publish dashboard",
        color: "error",
      });
    }
    this.isSystemDashboard =
      this.dashboardModel.type === "SYSTEM" ? true : false;
    localStorage.setItem("needReloadDashboard", "true");
  }

  public setReloadAllCard(reload: boolean = true) {
    this.needReload = [];
    for (const card of this.dashboardModel.reportCardList.items) {
      this.needReload.push({ id: card.id, value: reload });
    }
  }

  public isNeedReloadCard(id: number) {
    for (const item of this.needReload) {
      if (item.id === id) {
        return item.value;
      }
    }
  }

  public reloadCompleted(cardId: number) {
    for (const item of this.needReload) {
      if (item.id === cardId) {
        item.value = false;
      }
    }
  }

  public async moveCardLeft(cardId: number) {
    await this.dashboardModel.moveCardPosition(cardId, true);
    this.setReloadAllCard(true);
  }

  public async moveCardRight(cardId: number) {
    await this.dashboardModel.moveCardPosition(cardId, false);
    this.setReloadAllCard(true);
  }

  public deleteCard(reportCardId: number) {
    let i: number = 0;
    for (const card of this.dashboardModel.reportCardList.items) {
      if (card.id === reportCardId) {
        this.dashboardModel.reportCardList.items.splice(i, 1);
        break;
      }
      i++;
    }
  }
  public addCard() {
    this.$router.push(`/report-select/?dashboard_id=${this.dashboardModel.id}`);
  }

  public getMeasure(code: string) {
    for (const measure of this.measures.items) {
      if (measure.code === code) {
        return measure;
      }
    }
  }

  public getDimension(measureCode: string, dimensionCode: string) {
    const measure = this.getMeasure(measureCode);
    if (measure && measure.dimensions) {
      for (const dimension of measure.dimensions.items) {
        if (dimension.code === dimensionCode) {
          return dimension;
        }
      }
    }
  }

  public discardChanges() {
    this.useDefaultConfig = true;
    this.showSetting = false;
  }

  public async deleteDashboard() {
    try {
      this.saveLoading = true;
      await this.dashboardModel.delete();
      this.dialogDeleteDashboard = false;
      this.saveLoading = false;
      localStorage.setItem("needReloadDashboard", "true");
      EventBus.$emit("show-snackbar", {
        message: "Dashboard has been deleted",
      });
      this.$router.push("/my-dashboards");
    } catch (e: any) {
      
      this.saveLoading = false;
      EventBus.$emit("show-snackbar", {
        message: "Failed to delete dashboard",
        color: "error",
      });
    }
    
  }

  public async renameDashboard() {
    try {
      if (!this.newDashboardName) {
        this.renameDashboardError = true;
        return;
      }
      this.dashboardModel.name = this.newDashboardName;
      await this.dashboardModel.update();
      this.dialogRenameDashboard = false;
      EventBus.$emit("show-snackbar", {
        message: "Dashboard has been renamed",
      });
    } catch (e) {
      this.renameDashboardError = true;
      EventBus.$emit("show-snackbar", {
        message: "Failed to rename dashboard",
        color: "error",
      });
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  private async onUrlChange(newVal: any) {
    if (!this.isCreated) {
      return;
    }
    this.getLockBoardInfo();
    await this.fullLoad();
  }

  @Watch("dates", { immediate: true, deep: true })
  private async datesChange(newVal: any) {
    if (!this.isCreated) {
      return;
    }
    if (this.dates && this.dates.length > 2) {
      this.defaultRange = this.dates[2];
      this.cardDefaultRange = this.defaultRange;
      this.useDefaultConfig = false;
    }
  }

  @Watch("filterColumns", { immediate: true, deep: true })
  private async filterColumnsChange(newVal: any) {
    if (!this.isCreated) {
      return;
    }
    this.useDefaultConfig = false;
  }

  @Watch("comparisonDateModel", { immediate: true, deep: true })
  private async comparisonDateModelChange(newVal: any) {
    if (!this.isCreated) {
      return;
    }
    this.useDefaultConfig = false;
  }

  @Watch("productSegment", { immediate: true, deep: true })
  private async productSegmentChange(newVal: any) {
    if (!this.isCreated) {
      return;
    }
    this.useDefaultConfig = false;
  }
}
