
import { Component, Prop, Vue } from 'vue-property-decorator';
import Dashboard from '@/components/Dashboard.vue';
import { TemplateReportList } from '@/collections/template_reports';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    Dashboard,
  },
})
export default class SystemDashboard extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public dashboardId: number = 0;
  public dashboardType: string = '';
  public async created() {
    if (this.store.config.homepageDashboardId) {
      this.dashboardId = this.store.config.homepageDashboardId;
      this.dashboardType = this.store.config.homepageDashboardType;
    } else {
      this.dashboardId = 117;
      this.dashboardType = 'PUBLIC';
    }
  }
}
