
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from './HeaderTitle.vue';
import ActionButton from './ActionButton.vue';
import { StoreModel } from '@/models/store';
import { EventBus } from '@/main';
import { avatarColor } from '@/services/configs';
import { Title } from 'chart.js';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class VerticalMenuBar extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public isEmbeddedMode!: boolean;
  public mini: boolean = true;
  public search: string = '';
  public menuPurpose: string = '';

  public get getMenuItems() {
    const storeId: number = this.store.id;
    if (this.store.shopName === 'assisty-demo.myshopify.com') {
      return this.replenishmentMenu;
    }
    if (storeId > 9683) {
      return this.replenishmentMenu;
    }
    if (this.store.shopName === 'assisty-demo.myshopify.com') {
      this.menuPurpose = 'replenishment';
    }
    if (this.menuPurpose === 'replenishment') {
      return this.replenishmentMenu;
    }
    return this.FullMenu;
  }

  public replenishmentMenu: any[] = [

    {
      section: 'replenishment',
      title: 'Replenishment',
      items: [
        {
          title: 'Demand forecasting',
          icon: 'mdi-chart-timeline-variant-shimmer',
          type: 'group',
          items: [
            {
              title: 'Historical sales-based',
              link: '/reports/38/demand-forecasting/609',
            },
            {
              title: 'Inventory movement-based',
              link: '/reports/38/demand-forecasting/661',
            },
          ],
        },
        {
          title: 'Inventory replenishment',
          icon: 'mdi-invoice-clock',
          type: 'group',
          items: [
            {
              title: 'Replenishment metrics',
              link: '/board/1394',
            },
            {
              title: 'Reorder suggestion',
              link: '/reports/36/inventory-replenishment/672',
            },
            {
              title: 'Internal transfer suggestion',
              link: '/reports/measures/inventory_transfer_suggestion?dimension=by_variant',
            },
            {
              title: 'Replenishment params',
              link: '/settings/inventory-replenishment-parameters',
            },
            {
              title: 'Incoming inventory',
              link: '/settings/incoming-inventory',
            },

          ],
        },
        {
          title: 'Replenishment by model',
          icon: 'mdi-invoice-clock',
          type: 'group',
          items: [
            {
              title: 'Just-in-Time model',
              link: '/reports/36/inventory-replenishment/622',
            },
            {
              title: 'Fixed time period',
              link: '/reports/36/inventory-replenishment/627',
            },
            {
              title: 'Fixed order quantity',
              link: '/reports/36/inventory-replenishment/632',
            },
            {
              title: 'Min/max inventory quantity',
              link: '/reports/36/inventory-replenishment/602',
            },
            {
              title: 'Min/max stock cover days',
              link: '/reports/36/inventory-replenishment/601',
            },
            {
              title: 'Replenishment for bundle',
              link: '/reports/36/inventory-replenishment/608',
            },
          ],
        },
      ],
    },
    {
      section: 'Analytics',
      title: 'Analytics',
      icon: 'mdi-chart-bar',
      items: [
        {
          title: 'Reports library',
          icon: 'mdi-library',
          type: 'single',
          link: '/reports/0/all-categories',
        },
        {
          title: 'Inventory tracking',
          icon: 'mdi-store',
          type: 'group',
          items: [
            {
              title: 'Inventory tracking overview',
              link: '/board/1460/public',
            },
            {
              title: 'Inventory on-hand',
              link: '/reports/31',
            },
            {
              title: 'Low stock',
              link: '/card/view/1276',
            },
            {
              title: 'Out-of-stock',
              link: '/reports/288',
            },
            {
              title: 'Inventory remaining days',
              link: '/reports/212',
            },
            {
              title: 'Inventory movement',
              link: '/reports/291',
            },
            {
              title: 'Month-end inventory',
              link: '/reports/350',
            },
            {
              title: 'Inventory value',
              link: '/reports/595',
            },
          ],
        },
        {
          title: 'Inventory optimization',
          icon: 'mdi-cellphone-cog',
          type: 'group',
          items: [
            {
              title: 'Inventory metrics',
              link: '/board/337',
            },
            {
              title: 'Slow-moving product',
              link: '/reports/56',
            },
            {
              title: 'Dead stock',
              link: '/reports/207',
            },
            {
              title: 'Aging stock',
              link: '/reports/562',
            },
            {
              title: 'Sell-through rate',
              link: '/reports/186',
            },
            {
              title: 'Inventory turnover rate',
              link: '/reports/345',
            },
            {
              title: 'Inventory to sales',
              link: '/reports/642',
            },
            {
              title: 'Stock availability',
              link: '/reports/668',
            },
            {
              title: 'Stockout revenue analysis',
              link: '/board/372',
            },
          ],
        },
        {
          title: 'Sales performance',
          icon: 'mdi-chart-bar',
          type: 'group',
          items: [
            {
              title: 'Business overview',
              link: '/board/117/public',
            },
            {
              title: 'Sales overview',
              link: '/board/64/public',
            },
            {
              title: 'Daily sales',
              link: '/reports/measures/sales_overview?dimension=by_day&title=Daily%20Sales',
            },
            {
              title: 'Sales by geography',
              link: '/reports/measures/sales_overview?dimension=by_city&title=Sales%20',
            },
            {
              title: 'Product sales',
              link: '/reports/measures/total_sales?dimension=by_product&analytic=TopHigh&title=Top%20High%20Sales%20Products',
            },
            {
              title: 'Inventory sales matching',
              link: '/reports/measures/sales_on_hand_inventory?dimension=by_variant&title=Sales%20vs%20Inventory%20Matching',
            },
            {
              title: 'Product profitability',
              link: '/reports/measures/ProfitMargin?dimension=by_product&analytic=TopHigh&title=Top%20High%20Profit%20Margin%20Products',
            },
            {
              title: 'Product returns',
              link: '/board/347',
            },
          ],
        },
      ],
    },
    {
      section: 'My account',
      title: 'My account',
      icon: 'mdi-account',
      items: [
        {
          title: 'My reports',
          icon: 'mdi-chart-timeline-variant',
          type: 'single',
          link: '/my-reports',
        },
        {
          title: 'My dashboards',
          icon: 'mdi-chart-box-outline',
          type: 'single',
          link: '/my-dashboards',
        },
        {
          title: 'Product segmentation',
          icon: 'mdi-segment',
          type: 'group',
          items: [
            {
              title: 'Product segment listing',
              link: '/product-segment',
            },
            {
              title: 'Product segment builder',
              link: '/product/explore',
            },
          ],
        },
        {
          title: 'Report scheduling',
          icon: 'mdi-bell-ring',
          type: 'group',
          items: [
            {
              title: 'Scheduled reports',
              link: '/schedules',
            },
            {
              title: 'Schedule profiles',
              link: '/schedule/profiles',
            },
            {
              title: 'Schedule templates',
              link: '/schedule/templates',
            },
            {
              title: 'Email templates',
              link: '/email-templates',
            },
            {
              title: 'Email receivers',
              link: '/email-receivers',
            },
          ],
        },
        {
          title: 'Settings',
          icon: 'mdi-cogs',
          type: 'group',
          items: [
            {
              title: 'My subscription',
              link: '/subscription',
            },
            {
              title: 'Importing status',
              link: '/importing-status',
            },
            {
              title: 'Store setting',
              link: '/settings',
            },
            {
              title: 'Plugins',
              link: '/plugins',
            },
            {
              title: 'User management',
              link: '/users',
            },
            {
              title: 'Logout',
              link: '/logout',
            },
          ],
        }
      ],
    },

  ];

  public FullMenu: any[] = [
    {
      section: 'my-reports',
      title: 'My Reports',
      items: [
        {
          title: 'Reports library',
          icon: 'mdi-library',
          type: 'single',
          link: '/reports/0/all-categories',
        },
        {
          title: 'My reports',
          icon: 'mdi-chart-timeline-variant',
          type: 'single',
          link: '/my-reports',
        },
        {
          title: 'My dashboards',
          icon: 'mdi-chart-box-outline',
          type: 'single',
          link: '/my-dashboards',
        },
      ],
    },
    {
      section: 'Analytics',
      title: 'Analytics',
      items: [
        {
          title: 'Key metrics',
          icon: 'mdi-numeric',
          type: 'group',
          items: [
            {
              title: 'Business overview',
              link: '/board/117/public',
            },
            {
              title: 'Sales metrics',
              link: '/board/336',
            },
            {
              title: 'Finance metrics',
              link: '/board/335',
            },
            {
              title: 'Inventory metrics',
              link: '/board/337',
            },
          ],
        },
        {
          title: 'Inventory replenishment',
          icon: 'mdi-invoice-clock',
          type: 'group',
          items: [
            {
              title: 'Replenishment metrics',
              link: '/board/1394',
            },
            {
              title: 'Reorder suggestion',
              link: '/reports/36/inventory-replenishment/672',
            },
            {
              title: 'Just-in-time model',
              link: '/reports/36/inventory-replenishment/622',
            },
            {
              title: 'Fixed time period',
              link: '/reports/36/inventory-replenishment/627',
            },
            {
              title: 'Fixed order quantity',
              link: '/reports/36/inventory-replenishment/632',
            },
            {
              title: 'Min/Max inventory quantity',
              link: '/reports/36/inventory-replenishment/602',
            },
            {
              title: 'Min/max stock cover days',
              link: '/reports/36/inventory-replenishment/601',
            },
            {
              title: 'Replenishment for bundle',
              link: '/reports/36/inventory-replenishment/608',
            },
          ],
        },
        {
          title: 'Demand forecasting',
          icon: 'mdi-chart-timeline-variant-shimmer',
          type: 'group',
          items: [
            {
              title: 'Historical sales-based',
              link: '/reports/38/demand-forecasting/609',
            },
            {
              title: 'Inventory movement-based',
              link: '/reports/38/demand-forecasting/661',
            },
          ],
        },

        {
          title: 'Inventory tracking',
          icon: 'mdi-store',
          type: 'group',
          items: [
            {
              title: 'Inventory tracking overview',
              link: '/board/1460/public',
            },
            {
              title: 'Inventory on-hand',
              link: '/reports/31',
            },
            {
              title: 'Low stock',
              link: '/card/view/1276',
            },
            {
              title: 'Out-of-stock',
              link: '/reports/288',
            },
            {
              title: 'Inventory remaining days',
              link: '/reports/212',
            },
            {
              title: 'Inventory movement',
              link: '/reports/291',
            },
            {
              title: 'Month-end inventory',
              link: '/reports/350',
            },
            {
              title: 'Inventory value',
              link: '/reports/595',
            },
          ],
        },
        {
          title: 'Inventory optimization',
          icon: 'mdi-cellphone-cog',
          type: 'group',
          items: [
            {
              title: 'Slow-moving product',
              link: '/reports/56',
            },
            {
              title: 'Dead stock',
              link: '/reports/207',
            },
            {
              title: 'Aging stock',
              link: '/reports/562',
            },
            {
              title: 'Sell-through rate',
              link: '/reports/186',
            },
            {
              title: 'Inventory turnover rate',
              link: '/reports/345',
            },
            {
              title: 'Inventory to sales',
              link: '/reports/642',
            },
            {
              title: 'Stock availability',
              link: '/reports/668',
            },
            {
              title: 'Stockout revenue analysis',
              link: '/board/372',
            },
          ],
        },
        {
          title: 'Sales performance',
          icon: 'mdi-chart-bar',
          type: 'group',
          items: [
            {
              title: 'Sales overview',
              link: '/board/64/public',
            },
            {
              title: 'Daily sales',
              link: '/reports/measures/sales_overview?dimension=by_day&title=Daily%20Sales',
            },
            {
              title: 'Sales by geography',
              link: '/reports/measures/sales_overview?dimension=by_city&title=Sales%20by%20City',
            },
            {
              title: 'Sales by locations',
              link: '/reports/measures/sales_overview?dimension=by_location&title=Sales%20by%20Location',
            },
            {
              title: 'POS sales',
              link: '/reports/measures/total_sales?dimension=by_product&pivot_option=_rows_Location%5BLocation%5D_cols_%5BQuantitySold%5D&params=ShowTop__topn__text__single__All',
            },
          ],
        },
        {
          title: 'Product analytics',
          icon: 'mdi-tshirt-crew',
          type: 'group',
          items: [
            {
              title: 'Product sales',
              link: '/reports/measures/total_sales?dimension=by_product&analytic=TopHigh&title=Top%20High%20Sales%20Products',
            },
            {
              title: 'Inventory sales matching',
              link: '/reports/measures/sales_on_hand_inventory?dimension=by_variant&title=Sales%20vs%20Inventory%20Matching',
            },
            {
              title: 'Product profitability',
              link: '/reports/measures/ProfitMargin?dimension=by_product&analytic=TopHigh&title=Top%20High%20Profit%20Margin%20Products',
            },
            {
              title: 'Product returns',
              link: '/board/347',
            },
          ],
        },
      ],
    },

    {
      section: 'product-features',
      title: 'Product features',
      items: [
        {
          title: 'Product segmentation',
          icon: 'mdi-segment',
          type: 'group',
          items: [
            {
              title: 'Product segment listing',
              link: '/product-segment',
            },
            {
              title: 'Product segment builder',
              link: '/product/explore',
            },
          ],
        },
        {
          title: 'Report scheduling',
          icon: 'mdi-bell-ring',
          type: 'group',
          items: [
            {
              title: 'Scheduled reports',
              link: '/schedules',
            },
            {
              title: 'Schedule profiles',
              link: '/schedule/profiles',
            },
            {
              title: 'Schedule templates',
              link: '/schedule/templates',
            },
            {
              title: 'Email templates',
              link: '/email-templates',
            },
            {
              title: 'Email receivers',
              link: '/email-receivers',
            },
          ],
        },
        {
          title: 'Plugins',
          icon: 'mdi-toy-brick-search',
          type: 'single',
          link: '/plugins',
        },
        {
          title: 'User management',
          icon: 'mdi-account-multiple-plus',
          type: 'single',
          link: '/users',
        },
        {
          title: 'Recently downloaded files',
          icon: 'mdi-download',
          type: 'single',
          link: '/download-manager',
        }
      ],
    },
    {
      section: 'store-settings',
      title: 'Store settings',
      items: [
        {
          title: 'My subscription',
          icon: 'mdi-account-credit-card',
          type: 'single',
          link: '/subscription',
        },
        {
          title: 'Importing status',
          icon: 'mdi-autorenew',
          type: 'single',
          link: '/importing-status',
        },
        {
          title: 'Store setting',
          icon: 'mdi-cogs',
          type: 'single',
          link: '/settings',
        },
        {
          title: 'Book live demo',
          icon: 'mdi-calendar-account',
          type: 'single',
          link: '/live-demo',
        },
        {
          title: 'Logout',
          icon: 'mdi-logout',
          type: 'single',
          link: '/logout',
        },
      ],
    },
  ];
  public demoStoreName: string = 'assisty-demo.myshopify.com';

  public get avatarColor() {
    const s = this.store.information.name[0].toUpperCase();
    const value = s.charCodeAt(0);
    return avatarColor[value % avatarColor.length];
  }

  public get isTestingStore() {
    const testingStores = [
      'dariustest2.myshopify.com',
      'the-best-mega-store.myshopify.com',
      '2dba1f-4.myshopify.com',
    ];
    return testingStores.includes(this.store.shopName);
  }

  public searchReport() {
    this.$router.push('/reports/0/all-categories?keyword=' + this.search);
    this.search = '';
  }

  public doLogout() {
    this.$emit('logout');
  }

}
