import { get, put, del, post } from '@/services/http';

export class StoreConnectionModel {
  public id: number;
  public storeId: number;
  public storeName: string;
  public connectedStoreId: number;
  public connectedStoreName: string;
  public options: any;
  public status: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.storeId = data.store_id;
    this.storeName = data.store_name;
    this.connectedStoreId = data.connected_store_id;
    this.connectedStoreName = data.connected_store_name;
    this.options = data.options;
    this.status = data.status;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.storeId = data.store_id;
    this.storeName = data.store_name;
    this.connectedStoreId = data.connected_store_id;
    this.connectedStoreName = data.connected_store_name;
    this.options = data.options;
    this.status = data.status;
  }

  public async remove() {
    const res: any = await del(`/multistores/disconnect/${this.id}`);
  }

  public async request(storeName: string) {
    try {
      const res: any = await post('/multistores/request_connect', {
        store_name: storeName,
      });
      this.mapData(res.data);
    } catch (e: any) {
      if (JSON.parse(e.response.data).error) {
        throw JSON.parse(e.response.data).message;
      } else {
        throw e;
      }
    }
  }

  public async accept() {
    try {
      const res: any = await put(`/multistores/accept_request/${this.id}`);
      this.mapData(res.data);
    } catch (e: any) {
      if (JSON.parse(e.response.data).error) {
        throw JSON.parse(e.response.data).message;
      } else {
        throw e;
      }
    }
  }

  public async reject() {
    try {
      const res: any = await del(`/multistores/dismiss_request/${this.id}`);
      this.mapData(res.data);
    } catch (e: any) {
      if (JSON.parse(e.response.data).error) {
        throw JSON.parse(e.response.data).message;
      } else {
        throw e;
      }
    }
  }

  public async delRequest() {
    try {
      const res: any = await del(`/multistores/delete_request/${this.id}`);
      this.mapData(res.data);
    } catch (e: any) {
      if (JSON.parse(e.response.data).error) {
        throw JSON.parse(e.response.data).message;
      } else {
        throw e;
      }
    }
  }

  public async update() {
    try {
      const res: any = await put(`/multistores/update_connection/${this.id}`, {
        status: this.status,
        options: this.options,
      });
      this.mapData(res.data);
    } catch (e: any) {
      if (JSON.parse(e.response.data).error) {
        throw JSON.parse(e.response.data).message;
      } else {
        throw e;
      }
    }
  }
}
