
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get, post } from '@/services/http';
import Config from '../models/config';
import List from '../collections/list';
import SlackConnection from '../components/SlackConnection.vue';
import HeaderTitle from '../components/HeaderTitle.vue';
import SlackChannelSelector from '../components/SlackChannelSelector.vue';
import { StoreModel } from '@/models/store';
import UserChangePassword from '@/components/UserChangePassword.vue';
import ActionButton from '@/components/ActionButton.vue';
import SpreadsheetSettings from '../components/SpreadsheetSettings.vue';
import PluginConfiguration from '@/components/PluginConfiguration.vue';
import GoogleDriveSettings from '@/components/GoogleDriveSettings.vue';
import NotificationContact from '@/components/NotificationContact.vue';
import MultiStoreSettings from '@/components/MultiStoreSettings.vue';
import ReplenishmentSetting from '@/components/ReplenishmentSetting.vue';
import IncomingInventorySettings from '@/components/IncomingInventorySettings.vue';
import { MeasureList } from '@/collections/measures';
import BaseComponent from '@/components/BaseComponent.vue';
import { EventBus } from '@/main';
import ManageSuppliers from "@/components/ManageSuppliers.vue";
import InventoryTransferSettings from "@/components/InventoryTransferSettings.vue";
import AddChatChannel from "@/components/AddChatChannel.vue";
import AppcueTrigger from '@/components/AppcueTrigger.vue';

@Component({
  components: {
    SlackConnection,
    HeaderTitle,
    SlackChannelSelector,
    UserChangePassword,
    ActionButton,
    SpreadsheetSettings,
    PluginConfiguration,
    GoogleDriveSettings,
    NotificationContact,
    MultiStoreSettings,
    ReplenishmentSetting,
    IncomingInventorySettings,
    BaseComponent,
    ManageSuppliers,
    InventoryTransferSettings,
    AddChatChannel,
    AppcueTrigger,
  },
})
export default class Setting extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public config: Config = new Config();
  public isAddedSlack: boolean = false;
  public forceShowingWaringScheduleData: boolean = true;
  public emails: string[] = [];
  public currentValueEmail: string | null = null;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({default: null})
  public app!: any;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public pageTitles: any = {
    'contact': 'Contact Information',
    'bundle-product-mapping': 'Bundle Product Mapping',
    'inventory-replenishment-parameters': 'Inventory Replenishment Parameters',
    'stocky': 'Stocky Integration',
    'multi-store-settings': 'Multiple Store Data Combination',
    'manage-suppliers': 'Manage Suppliers',
    'incoming-inventory': 'Incoming Inventory',
    'internal-inventory-transfer-settings': 'Internal Inventory Transfer Settings'
  };
  public isExpandPageMenu: boolean = true;
  public appcueFlows: any = [
    {
      tab: 'inventory-replenishment-parameters',
      name: 'replenishment-setting-overview',
    }
  ];
  public demoStoreName: string = 'assisty-demo.myshopify.com';

  public async created() {
    this.loading = true;
    try {
      await this.config.load();
      this.error = false;
      this.success = true;
      this.loading = false;
    } catch (e: any) {
      this.loading = false;
      this.error = true;
      this.success = false;
      return;
    }
  }

  public get canShowAppcueFlow() {
    if (this.appcueFlow === '') {
      return false;
    }
    return true;
  }

  public get appcueFlow() {
    const flow = this.appcueFlows.find((f: any) => f.tab === this.currentTab);
    if (flow && flow !== undefined) {
      return flow.name;
    }
    return '';
  }

  public reloadReportData() {
    EventBus.$emit('reload-report-data');
  }

  public expandPageMenu() {
    this.isExpandPageMenu = !this.isExpandPageMenu;
    localStorage.setItem("isExpandPageMenu", this.isExpandPageMenu + "");
  }

  public get currentTabTitle() {
    return this.pageTitles[this.currentTab];
  }

  get currentTab() {
    let tab = this.$route.params.tab;
    const tabs = [
    'contact',
    'chat-channel', 
    'bundle-product-mapping', 
    'inventory-replenishment-parameters', 
    'stocky', 
    'multi-store-settings', 
    'manage-suppliers', 
    'incoming-inventory',
    'internal-inventory-transfer-settings',
  ];
    if (tabs.includes(tab)) {
      return tab;
    } else {
      return 'contact';
    }
  }

  public remove(item: string) {
    this.emails.splice(this.emails.indexOf(item), 1);
    this.emails = [...this.emails];
  }

  private async checkAddedSlack() {
    const res: any = await get('/stores/detail');
    if (res.data.channel_connected === 'slack') {
      this.isAddedSlack = true;
    }
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
