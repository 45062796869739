
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import { get } from '@/services/http';
import { StoreModel } from '@/models/store';
import { EventHandler } from '@/modules/events';
import ActionButton from '@/components/ActionButton.vue';
import ScheduleCreate from '@/components/ScheduleCreate.vue';
import { ScheduleModel } from "@/models/schedule";

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    ScheduleCreate,
  },
})
export default class Schedule extends Vue {
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({default: null})
  public app!: any;
  public loading: boolean = false;
  public initSchedule: ScheduleModel = new ScheduleModel();
  public createScheduleStep: number = 1;

  public async created() {
    await this.initScheduleData();
  }

  public async initScheduleData() {
    this.loading = true;
    let scheduleId: number = Number(this.$route.params.schedule_id);
    if (scheduleId) {
      this.initSchedule.id = scheduleId;
      await this.initSchedule.fetch();
    } else {
      try {
        const scheduleToCreate: any = localStorage.getItem('schedule_to_create');
        if (!scheduleToCreate) {
          this.$router.push({name: 'scheduleListing'});
        }
        this.initSchedule.mapData(JSON.parse(scheduleToCreate));
        try {
          const tmp: any = sessionStorage.getItem('create_schedule_step');
          if (tmp) {
            this.createScheduleStep = parseInt(tmp);
          }
        } catch (e: any) {
          // console.log(e);
        }
      } catch (e: any) {
        this.$router.push({name: 'scheduleListing'});
      }
    }
    this.loading = false;
  }
  
}
