import { get, post, put } from '@/services/http';
import { RuleList } from '@/collections/rules';

export class UserPackageModel {
  public id: number;
  public name?: string;
  public price: number;
  public discount: number;
  public durationLimitInIntervals?: number;
  public activatedAt?: string;
  public rules: RuleList;
  public status?: string;
  public chargeId?: number;
  public billingPackageId: number;
  public discountCode?: string;
  public billingPackageVersion?: number;
  public cancelledAt?: string;
  public trialEndAt?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public interval: string = 'EVERY_30_DAYS';

  constructor(data: any = {}) {
    this.id = data.id;
    this.billingPackageId = data.billingPackageId;
    this.rules = new RuleList();
    this.discount = data.discount || 0;
    this.price = data.price || 0;
  }

  public get passedTrial() {
    return (this.toTimestamp(undefined) - this.toTimestamp(this.trialEndAt)) > 0;
  }

  public toTimestamp(strDate: string|undefined) {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  public async fetch() {
    const res: any = await get(`/current_package`);
    this.mapData(res.data);
  }

  public async create(trialDays: number = 7, discount: number = 0,
                      returnUrl: string, needInstallPlugins: number[] = [], isYearly: boolean = false) {
                
    let interval: string = 'EVERY_30_DAYS';
    if (isYearly) {
      interval = 'ANNUAL';
    }
    const res: any = await post(`/user_packages`, {
      billingPackageId: this.billingPackageId,
      trialDays,
      discountPercentage: discount,
      returnUrl,
      discountCode: this.discountCode,
      plugins: needInstallPlugins,
      interval: interval,
    });
    this.mapData(res.data.userPackage);
    return res.data.confirmationUrl;
  }

  public async confirmed() {
    const res: any = await put(`/user_packages/active`, {
      chargeId: this.chargeId,
    });
    this.mapData(res.data.userPackage);
  }

  public async getCurrent() {
    try {
      const res: any = await get(`/user_packages/current`);
      this.mapData(res.data.userPackage);
    } catch {
      // skipped
    }
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.price = data.price;
    this.discount = data.discount;
    this.durationLimitInIntervals = data.durationLimitInIntervals;
    this.activatedAt = data.activatedAt;
    this.rules = new RuleList();
    this.rules.mapData(data.rules);
    this.status = data.status;
    this.chargeId = data.chargeId;
    this.billingPackageId = data.billingPackageId;
    this.billingPackageVersion = data.billingPackageVersion;
    this.cancelledAt = data.cancelledAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.interval = data.interval;
    this.trialEndAt = data.trialEndAt;
  }
}
