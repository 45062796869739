
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import { StoreModel } from '@/models/store';
import moment from 'moment';
import { ParamModel } from '@/models/param';

interface Range {
  start?: any;
  end?: any;
}

@Component({
  components: {},
})
export default class DateFilterParam extends Vue {
  @Prop({ default: false })
  public disabled!: boolean;
  public defaultRange: string = '';
  public dates: string[] = [];
  public range: Range = {};
  public dateRangeMenu: boolean = false;
  @Prop({ default: () => new ParamModel() })
  public value!: ParamModel;
  @Prop({ default: () => new ParamModel() })
  public initParam!: ParamModel;
  public customDateRange: string = '';
  public defaultRanges: any = [
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: 'Yesterday',
      value: 'yesterday',
    },
    {
      text: 'Week to date',
      value: 'week-to-date',
    },
    {
      text: 'Last week',
      value: 'last-week',
    },
    {
      text: 'Month to date',
      value: 'month-to-date',
    },
    {
      text: 'Last month',
      value: 'last-month',
    },
    {
      text: 'Last 7 days',
      value: '7d',
    },
    {
      text: 'Last 14 days',
      value: '14d',
    },
    {
      text: 'Last 30 days',
      value: '30d',
    },
    {
      text: 'Last 90 days',
      value: '3m',
    },
    {
      text: 'Last 180 days',
      value: '6m',
    },
    {
      text: 'Last year',
      value: 'last-year',
    },
    {
      text: 'This year',
      value: 'year-to-date',
    },
    {
      text: 'Last 3 years',
      value: '3y',
    },
    {
      text: 'this fiscal year',
      value: 'this-fiscal-year',
    },
  ];
  public created() {
    for (let i = 0; i < this.defaultRanges.length; i++) {
      const item = {
        text: this.$t(`filter.${ this.defaultRanges[i].text}`),
        value: this.defaultRanges[i].value,
        disabled: this.defaultRanges[i].disabled,
      };
      this.defaultRanges[i] = item;
    }
  }

  public getPastDate(days: number) {
    const installedDate = new Date();
    const year = installedDate.getFullYear();
    const month = installedDate.getMonth();
    const day = installedDate.getDate() - days;
    return new Date(year, month, day).toDateString();
  }

  public toTimestamp(strDate: string = '') {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  public isGreaterDate(firstDateStr: string, secondDateStr: string) {
    if (this.toTimestamp(firstDateStr) - this.toTimestamp(secondDateStr) > 0) {
      return true;
    } else {
      return false;
    }
  }

  public get customRangeText() {
    if (this.dates.length >= 2) {
      const startDateStr = moment(this.dates[0]).format('MMM D, YYYY');
      const endDateStr = moment(this.dates[1]).format('MMM D, YYYY');
      return `${startDateStr}  →  ${endDateStr}`;
    }
    return this.disabled ? 'No Date Filter Available' : 'Select a date Range';
  }

  private convertTimeRange(timerange: string) {
    const now = moment();
    let startDate;
    let endDate;
    switch (timerange) {
      case 'today':
        startDate = now;
        endDate = now;
        break;
      case 'yesterday':
        const yesterday = now.clone().subtract(1, 'days');
        startDate = yesterday;
        endDate = yesterday;
        break;
      case '7d':
        startDate = now.clone().subtract(7, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '14d':
      startDate = now.clone().subtract(14, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
      case '3m':
        startDate = now.clone().subtract(3, 'months');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '6m':
        startDate = now.clone().subtract(6, 'months');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '1y':
        startDate = now.clone().subtract(1, 'years');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '3y':
        startDate = now.clone().subtract(3, 'years');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '30d':
        startDate = now.clone().subtract(30, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case 'week-to-date':
        startDate = now.clone().startOf('week');
        endDate = now.clone().endOf('day');
        break;
      case 'month-to-date':
        startDate = now.clone().startOf('month');
        endDate = now.clone().endOf('day');
        break;
      case 'year-to-date':
        startDate = now.clone().startOf('year');
        endDate = now.clone().endOf('day');
        break;
      case 'last-week':
        startDate = now.clone().subtract(1, 'week').startOf('week');
        endDate = now.clone().subtract(1, 'week').endOf('week');
        break;
      case 'last-month':
        startDate = now.clone().subtract(1, 'month').startOf('month');
        endDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'last-year':
        startDate = now.clone().subtract(1, 'year').startOf('year');
        endDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      case 'this-fiscal-year':
        const fiscalYear = this.getFiscalYearTimestamps();
        startDate = fiscalYear.start;
        endDate = fiscalYear.end;
        break;
      case 'custom':
        break;
    }
    const range = {
      start: startDate,
      end: endDate,
    };
    return range;
  }

  public getFiscalYearTimestamps() {
    const startDate = "2024-02-04";
    const endDate = "2025-02-01";

    // Convert the start and end dates to moment date objects
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');

    // Return the start and end date objects
    return { start, end };
  }

  @Watch('defaultRange', { immediate: true, deep: true })
  private async onDefaultRangeChanged(newVal: any, oldVal: any) {
    if (this.defaultRange) {
      this.range = this.convertTimeRange(this.defaultRange);
      this.customDateRange = this.defaultRange;
    }
  }

  @Watch('customDateRange', { immediate: true, deep: true })
  private async onCustomDateRangeChanged(newVal: any, oldVal: any) {
    if (!this.customDateRange || this.customDateRange === 'custom') {
      return;
    }
    this.defaultRange = this.customDateRange;
  }

  @Watch('dates', { immediate: true, deep: true })
  private async onDatesChanged(newVal: any) {
    if (this.dates.length < 2) {
      return;
    }
    const param = this.initParam;
    const values = [];
    values.push(this.customRangeText);
    param.value = values;
    if (this.customDateRange !== 'custom') {
      param.default = this.customDateRange;
    }

    this.$emit('input', param);
  }

  @Watch('initParam', { immediate: true, deep: true })
  private async onInitParamChanged(newVal: any) {
    if (this.initParam && this.initParam.default) {
    this.defaultRange = this.initParam.default;
    }
  }

  @Watch('range', { immediate: true, deep: true })
  private async onRangeChanged(newVal: any) {
    if (this.range && this.range.start && this.range.end) {
      if (this.customDateRange === 'custom') {
        this.dates = [
        dateFormat(this.range.start, 'yyyy-mm-dd'),
        dateFormat(this.range.end, 'yyyy-mm-dd'),
        this.customDateRange,
      ];
      } else {
        this.dates = [
        dateFormat(this.range.start, 'yyyy-mm-dd'),
        dateFormat(this.range.end, 'yyyy-mm-dd'),
        this.defaultRange,
      ];
      }
    } else {
      this.dates = [];
    }
    this.dateRangeMenu = false;
  }

  @Watch('showDateRangeMenu', { immediate: true, deep: true })
  private async onShowDateRangeMenuChange(newVal: any) {
    if (newVal && newVal === true) {
      this.dateRangeMenu = true;
    }
  }
}
