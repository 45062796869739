
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { StoreModel } from '@/models/store';
import { RuleModel } from '@/models/rule';
import { ComparisonDateModel } from '@/models/comparison_date';
import { ReportCardModel } from '@/models/report_card';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import { TemplateReportList } from '@/collections/template_reports';
import { MeasureList } from '@/collections/measures';
import { FilterColumnList } from '@/collections/filter_columns';
import { CustomReportList } from '@/collections/custom_reports';
import { ParamList } from '@/collections/params';
import { EventHandler } from '@/modules/events';
import ColumnSelector from '@/components/ColumnSelector.vue';
import ReportCard from '../components/ReportCard.vue';
import ActionButton from '@/components/ActionButton.vue';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import ChatWithUs from '@/components/ChatWithUs.vue';
import HeaderTitle from '@/components/HeaderTitle.vue';
import TableReport from '@/components/TableReport.vue';
import DateFilter from '@/components/DateFilter.vue';
import GroupByDimension from '@/components/GroupByDimension.vue';
import AnalyticsTypeFilter from '@/components/AnalyticsTypeFilter.vue';
import FilterColumn from '@/components/FilterColumn.vue';
import ReportParam from '@/components/ReportParam.vue';
import ComparisonDate from '@/components/ComparisonDate.vue';
import { EventBus } from '@/main';
import dateFormat from 'dateformat';
import { DimensionColumnList } from '@/collections/dimension_columns';
import { ParamModel } from '@/models/param';
import LoadingSlider from '@/components/LoadingSlider.vue';
import { ProductSegmentModel } from '@/models/product_segment';
import SegmentSelector from '@/components/SegmentSelector.vue';
import { MetricList } from "@/collections/metrics";

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

@Component({
  components: {
    TableReport,
    HeaderTitle,
    DateFilter,
    GroupByDimension,
    AnalyticsTypeFilter,
    ChatWithUs,
    FilterColumn,
    FeatureLockedNotify,
    ColumnSelector,
    ReportCard,
    ActionButton,
    ReportParam,
    ComparisonDate,
    LoadingSlider,
    SegmentSelector,
  },
})
export default class CardDesign extends Vue {

  public get getPageSize() {
    if (this.showSetupMenu) {
      return 'page-show-setup';
    } else {
      return 'page-hide-setup';
    }
  }


  public get getDatatableSize() {
    if (this.isExpandPageMenu) {
      return "datatable-show-action-menu";
    } else {
      return "datatable-hide-action-menu";
    }
  }

  public get getPageMenuSize() {
    if (this.isExpandPageMenu) {
      return "page_menu_big";
    } else {
      return "page_menu_small";
    }
  }



  public get filterReportColumns() {
    let reportColumns: any = [];
    if (this.measure.filterDimensions.size() > 0) {
      for (const dim of this.measure.filterDimensions.toArray()) {
        reportColumns.push({
          name: dim.label ? dim.label : dim.name,
          code: dim.fieldCode,
          dataType: dim.dataType,
          luisMapping: dim.code,
        });
      }
    }
    for (const column of this.reportColumns) {
      reportColumns.push(column);
    }
    reportColumns = this.refineFilterColumns(reportColumns);
    return reportColumns;
  }

  public get dateRangeText() {
    return `${dateFormat(this.dates[0], 'mmm d, yyyy', true)} - ${dateFormat(this.dates[1], 'mmm d, yyyy', true)}`;
  }
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public measure: MeasureModel = new MeasureModel();
  public dimension: DimensionModel = new DimensionModel();
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public analyticsTypeCode: string = '';
  public newName: string = '';
  public readyToGetReport: boolean = false;
  public loading: boolean = false;
  public loadingSaveReport: boolean = false;
  public loadingSaveAs: boolean = false;
  public loadingRename: boolean = false;
  public loadingDelete: boolean = false;
  public dates: string[] = [];
  public comparisonDateModel: ComparisonDateModel = new ComparisonDateModel();
  public defaultRange: string = '';
  public timeFilters = ['by_day', 'by_week', 'by_month', 'by_quarter', 'by_year'];
  public filterDimensions: FilterDimensionList = new FilterDimensionList();
  public reportCardModel: ReportCardModel = new ReportCardModel();
  public currentReportCardModel: ReportCardModel = new ReportCardModel();
  public useDefaultConfig: boolean = false;
  public dialogSaveAs: boolean = false;
  public dialogRename: boolean = false;
  public dialogDelete: boolean = false;
  public dialogShareReport: boolean = false;
  public linkCopied: boolean = false;
  public rule: RuleModel = new RuleModel({ code: 'favorite_reports_count' });
  public isInitFilterFromParam: boolean = false;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public isLocked: string = 'AVAILABLE';
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public reportParams: ParamList = new ParamList();
  public initReportParams: ParamList = new ParamList();
  public availableParams: ParamList = new ParamList();
  public reportColumns: any = [];
  public initReportColumns: any = [];
  public initReportFilterColumns: any = [];
  public selectedReportColumns: any = [];
  public savedReportColumns: any = [];
  public isResetFilterColumns: boolean = false;
  public customReports: CustomReportList = new CustomReportList();
  public pivotOption: string = '';
  public reportName: string = '';
  public columnFilterMenu: boolean = false;
  public groupByDimensionMenu: boolean = false;
  public filterDateRangeMenu: boolean = false;
  public reportGuideMenu: boolean = false;
  public columnSelectorMenu: boolean = false;
  public dateRangeMenu: boolean = false;
  public showActions: boolean = true;
  public breakpoint: string = 'md';
  public pageLayoutShowChart: boolean = true;
  public pageLayoutShowTable: boolean = true;
  public needSaveCard: boolean = false;
  public saveCardSuccessMessage: string = '';
  public allowSaveCard: boolean = false;
  public isCreated: boolean = false;
  public showSetupMenu: boolean = false;
  public setupModule: string = 'column';
  public chartType: string = 'BIG_NUMBER';
  public activeDimensions: DimensionColumnList = new DimensionColumnList();
  public columnSelectorAction: string = 'column';
  public showPivot: boolean = false;
  public needRefreshTableData: boolean = false;
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public initPageOptions: Option = {};
  public pageOptions: Option = {};
  public calculatedMetrics: MetricList = new MetricList();
  public initCalculatedMetrics: MetricList = new MetricList();
  public isExpandPageMenu: boolean = true;
  public async created() {
    this.loading = true;
    await this.fullLoadData();
    await this.initActiveDimensions();
    this.isCreated = true;
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`Design Card`, {
        CardName: this.reportCardModel.name,
        cardId: this.reportCardModel.id,
        measure: this.reportCardModel.measure.code,
        dimension: this.reportCardModel.dimension ? this.reportCardModel.dimension.code : '',
      });
    } catch (e) {
      // ignore this
    }
    EventBus.$on('update-pivot-option', async (pivotOption: any) => {
      this.pivotOption = pivotOption;
      this.columnSelectorAction = 'column';
      this.needRefreshTableData = true;
    });
    EventBus.$on('close-column-selector-form', async (data: any) => {
      this.showSetupMenu = false;
    });
  }

  public expandPageMenu() {
    this.isExpandPageMenu = !this.isExpandPageMenu;
    localStorage.setItem("isExpandPageMenu", this.isExpandPageMenu + "");
  }

  public getPageMenuStatus() {
    if (localStorage.getItem("isExpandPageMenu") === "false") {
      this.isExpandPageMenu = false;
    } else {
      this.isExpandPageMenu = true;
    }
  }
  public updateCalculatedMetric(data: any) {
    if (!data || !data.metrics || data.metrics.items.length === 0) {
      return;
    }

    this.calculatedMetrics = data.metrics;
    this.showSetupMenu = false;
    this.reportCardModel.calculatedMetrics = this.calculatedMetrics;
    try {
      this.reportCardModel.update();
    } catch (e: any) {
      //
    }

  }

  public needRefreshTableDataEvent() {
    this.needRefreshTableData = true;
  }

  public refreshMetricDataCompleted() {
    this.needRefreshTableData = false;
  }

  public actionPivotClick() {
    this.columnSelectorAction = 'pivot';
    EventBus.$emit('close-column-view-form');
  }

  public actionManageColumnClick() {
    this.columnSelectorAction = 'column';
    EventBus.$emit('close-column-view-form');
  }

  public actionAddCalculatedMetricClick() {
    this.columnSelectorAction = "metric";
  }

  public actionCloseSetupMenu() {
    this.showSetupMenu = false;
    EventBus.$emit('close-column-view-form');
  }

  public actionShowSetupColumn() {
    this.showSetupMenu = true;
    this.columnSelectorAction = 'column';
    EventBus.$emit('close-column-view-form');
  }

  public async initAvailableParams() {
    if (!this.activeDimensions || this.activeDimensions.size() === 0) {
      await this.initActiveDimensions();
    }
    this.availableParams = new ParamList();
    if (this.measure.params && this.measure.params.size() > 0) {
      for (const item of this.measure.params.items) {
        this.availableParams.add(item);
      }
    }
    for (const dimension of this.activeDimensions.items) {
      if (dimension.luis_mapping === this.dimension.code) {
        if (dimension.params && dimension.params.size() > 0) {
          this.dimension.params = dimension.params;
          for (const item of dimension.params.items) {
            if (this.canAddAvailableParam(item)) {
              this.availableParams.add(item);
            }
          }
        }
      }
    }
  }

  public initParamFromDefaultValue() {
    if (this.initReportParams.size() === 0) {
      for (const item of this.availableParams.items) {
        if (item.value && item.value.length > 0) {
          const param = new ParamModel();
          param.NeedApiRequest = item.NeedApiRequest;
          param.code = item.code;
          param.listValues = item.listValues;
          param.name = item.name;
          param.sortOrder = item.sortOrder;
          param.value = item.value;
          param.valueType = item.valueType;
          param.dataType = item.dataType;
          param.default = item.default;

          this.initReportParams.add(param);
          this.reportParams.add(param);
        }
      }
    }
  }

  public canAddAvailableParam(param: ParamModel) {
    for (const item of this.availableParams.items) {
      if (item.code === param.code) {
        return false;
      }
    }
    return true;
  }

  public async initActiveDimensions() {
    this.activeDimensions = new DimensionColumnList();
    await this.activeDimensions.fetch();
  }

  public notResetColumnFilter(e: any) {
    this.isResetFilterColumns = false;
  }

  public changeChartType(chartType: string) {
    this.chartType = chartType;
    EventBus.$emit('report-card-change-chart-type', chartType);
  }

  public updateReportColumn(data: any) {
    if (data.reportColumnList && data.reportColumnList.length > 0) {
      this.initReportColumns = data.reportColumnList;
    } else {
      this.initReportColumns = data.reportColumns;
    }

    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      this.selectedReportColumns = data.reportColumns;
    }

  }

  public changePageLayout(showChart: boolean = true, showTable: boolean = true) {
    this.pageLayoutShowChart = showChart;
    this.pageLayoutShowTable = showTable;
  }

  public refineFilterColumns(reportColumns: any) {
    const duplicateColumns: any = {
      'Item[Item Category]': 'product_collections',
      'product_tag': 'product_tags',
      'order_tag': 'order_tags',
      'customer_tag': 'customer_tags',
    };

    for (const column of reportColumns) {
      if (Object.keys(duplicateColumns).includes(column.code)) {
        let index: number = 0;
        for (const column2 of reportColumns) {
          if (column2.code === duplicateColumns[column.code]) {
            reportColumns.splice(index, 1);
            break;
          }
          index++;
        }
      }
    }
    return reportColumns;
  }

  public getTranslatedReportName(reportName: string) {
    if (this.$te(`report_name.${reportName}`)) {
      return this.$t(`report_name.${reportName}`);
    } else {
      return reportName;
    }
  }

  public getDate(date: string) {
    return dateFormat(date, 'mmm d, yyyy');
  }
  public async CopyReportLink() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`Copy link`, {
        measure: this.measure.code,
        dimension: this.dimension.code,
      });
    } catch (e) {
      // ignore this
    }
    let link = `${process.env.VUE_APP_HOST}/reports/measures/` + this.measure.code;
    link = link + '?dimension=' + this.dimension.code;
    if (this.analyticsType.code && this.analyticsType.code !== undefined) {
      link = link + '&analytic=' + this.analyticsType.code;
    }
    if (this.dates && this.dates.length >= 2) {
      link = link + '&start_time=' + this.dates[0];
      link = link + '&end_time=' + this.dates[1];
    }

    const filterParams = [];
    for (const item of this.filterColumns.items) {
      filterParams.push(`${item.name}__${item.code}__${item.operator}__${item.value.join('_or_')}__${item.dataType}`);
    }
    if (filterParams.length > 0) {
      link = link + '&filter=' + filterParams.join('_col_');
    }
    if (this.pivotOption) {
      link = link + '&pivot_option=' + this.pivotOption;
    }
    navigator.clipboard.writeText(encodeURI(link));
    this.linkCopied = true;
    await this.sleep(3000);
    this.linkCopied = false;
  }

  public operatorName(value: string) {
    const operators: any = [
      { name: 'equal', value: 'eq' },
      { name: 'equal (case-insensitive)', value: 'eqi' },
      { name: 'not equal', value: 'ne' },
      { name: 'is lower than', value: 'lt' },
      { name: 'is lower than or equal to', value: 'lte' },
      { name: 'is greater than', value: 'gt' },
      { name: 'is greater than or equal to', value: 'gte' },
      { name: 'contains', value: 'contains' },
      { name: 'not contains', value: 'notContains' },
      { name: 'is blank', value: 'null' },
      { name: 'is not blank', value: 'notNull' },
    ];
    for (const item of operators) {
      if (item.value === value) {
        return item.name;
      }
    }
    return value;
  }

  public filterValuesToString(values: any) {
    if (!values) {
      return '';
    }
    return values.join(' or ');
  }

  public goBack() {
    const goBackUrl: string = '/myboard/' + this.reportCardModel.dashboardIds;
    this.$router.push(goBackUrl);
  }

  public saveCard() {
    this.loadingSaveReport = true;
    this.needSaveCard = true;
  }

  public async saveCardCompleted() {
    this.loadingSaveReport = false;
    this.needSaveCard = false;
    await this.fullLoadData();
  }
  public async fullLoadData() {
    try {
      this.loading = true;
      // load data from card
      this.reportCardModel = new ReportCardModel();
      if (this.$route.params.id) {
        this.reportCardModel.id = Number(this.$route.params.id);
        await this.reportCardModel.fetch();
        this.reportName = this.reportCardModel.name;
        this.measure.code = this.reportCardModel.measure.code;
        this.measure = await this.defineMeasure();
        // this.reportCardModel.measure = this.measure;
        if (this.reportCardModel.dimension) {
          for (const dimension of this.measure.dimensions.items) {
            if (dimension.code === this.reportCardModel.dimension.code) {
              this.dimension = dimension;
            }
          }
        }
        if (this.reportCardModel.analyticsType && this.reportCardModel.analyticsType.code) {
          for (const analyticsType of this.measure.analyticsTypes.items) {
            if (analyticsType.code === this.reportCardModel.analyticsType.code) {
              this.analyticsType = analyticsType;
              this.analyticsTypeCode = analyticsType.code;
            }
          }
        }
        this.initFilterColumns = this.reportCardModel.filterColumns;
        this.filterColumns = this.reportCardModel.filterColumns;
        this.pivotOption = this.reportCardModel.pivotOption;
        this.savedReportColumns = this.reportCardModel.reportColumns;
        this.selectedReportColumns = this.reportCardModel.reportColumns;
        this.initPageOptions = this.reportCardModel.pageOptions;
        this.pageOptions = this.reportCardModel.pageOptions;
        this.initCalculatedMetrics = this.reportCardModel.calculatedMetrics;
        this.calculatedMetrics = this.reportCardModel.calculatedMetrics;
        this.productSegment = new ProductSegmentModel({ id: this.reportCardModel.productSegmentId });
        if (this.reportCardModel.defaultRange) {
          this.defaultRange = this.reportCardModel.defaultRange;
        }
        if (this.reportCardModel.filterTimerange) {
          this.dates = this.reportCardModel.filterTimerange;
        }
        if (this.reportCardModel.reportParams && this.reportCardModel.reportParams.items.length > 0) {
          this.initReportParams = this.reportCardModel.reportParams;
        }
      }
      if (this.measure.dimensions.size() > 0) {
        if (!this.dimension.code) {
          if (this.measure.hasTimerange) {
            for (const dimension of this.measure.dimensions.items) {
              if (['by_day', 'by_week', 'by_month', 'by_quarter',
                'by_year'].includes(dimension.code)) {
                this.dimension = dimension;
                break;
              }
            }
          } else {
            this.dimension = this.measure.dimensions.items[0];
          }
        }
      } else {
        this.measure.hasTimerange = false;
      }
      await this.initAvailableParams();
      this.initParamFromDefaultValue();
      await this.setDefaultTimerange();
      this.loading = false;
      this.readyToGetReport = true;
      this.checkAllowSaveCard();
    } catch (e) {
      //show error
      this.loading = false;
      this.readyToGetReport = false;
      EventBus.$emit('show-snackbar', { message: 'Error loading data', color: 'error' });
    }
  }

  public async defineMeasure() {
    for (const measure of this.measures.items) {
      if (measure.code === this.measure.code) {
        return measure;
      }
    }
    await this.measure.fetch();
    return this.measure;
  }

  public async setDefaultTimerange() {
    if (this.defaultRange) {
      return;
    }
    if (!this.measure.hasTimerange) {
      return;
    }
    if (this.reportCardModel.defaultRange) {
      this.defaultRange = this.reportCardModel.defaultRange;
      return;
    }

    if (this.dates && this.dates.length > 2) {
      this.defaultRange = 'custom';
      return;
    }
    const timeFilters = ['by_day', 'by_week', 'by_month', 'by_quarter', 'by_year'];
    switch (this.dimension.code) {
      case 'by_week':
        this.defaultRange = '30d';
        break;
      case 'by_month':
        this.defaultRange = '6m';
        break;
      case 'by_quarter':
        this.defaultRange = '1y';
        break;
      case 'by_year':
        this.defaultRange = '3y';
        break;
      case 'by_day':
        this.defaultRange = '7d';
        break;
      default:
        this.defaultRange = '30d';
        break;
    }
  }

  public changeDimension(dimension: DimensionModel) {
    this.dimension = dimension;
  }

  public showFilterColumnMenu() {
    this.columnFilterMenu = true;
  }

  public closeColumnFilterMenu() {
    this.columnFilterMenu = false;
  }

  public showGroupByDimensionMenu() {
    this.groupByDimensionMenu = true;
  }

  public closeGroupByDimensionMenu() {
    this.groupByDimensionMenu = false;
  }

  public showDateRangeMenu() {
    this.dateRangeMenu = true;
  }

  public showReportGuideMenu() {
    this.reportGuideMenu = true;
  }

  public closeColumnSelectorMenu() {
    this.columnSelectorMenu = false;
  }

  public showColumnSelectorMenu() {
    this.columnSelectorMenu = true;
  }

  public closeReportGuideMenu() {
    this.reportGuideMenu = false;
  }

  public closeDateRangeMenu() {
    this.dateRangeMenu = false;
  }

  public showSaveAsFavoriteReportMenu() {
    this.dialogSaveAs = true;
  }

  public assignInitReportFilterColumns(reportFilterColumns: any) {
    this.initReportFilterColumns = reportFilterColumns;
  }

  public checkAllowSaveCard() {
    if (!this.isCreated) {
      return false;
    }
    this.allowSaveCard = false;
    // check if the date ranges changed.
    if (this.reportCardModel.defaultRange !== this.dates[2]) {
      this.allowSaveCard = true;
    }
    if (this.reportCardModel.filterTimerange) {
      if (this.reportCardModel.filterTimerange[0] !== this.dates[0]
        || this.reportCardModel.filterTimerange[1] !== this.dates[1]) {
        this.allowSaveCard = true;
      }
    }
    // check if the analyticsType changed
    if (this.reportCardModel.analyticsType && this.analyticsType.code !== this.reportCardModel.analyticsType.code) {
      this.allowSaveCard = true;
    }
    // check if the filterColumn changes
    if (this.filterColumns.items.length !== this.reportCardModel.filterColumns.items.length) {
      this.allowSaveCard = true;
    } else {
      for (const oldFilter of this.reportCardModel.filterColumns.items) {
        let changed: boolean = true;
        for (const newFilter of this.filterColumns.items) {
          if (newFilter.code === oldFilter.code &&
            newFilter.dataType === oldFilter.dataType &&
            newFilter.name === oldFilter.name &&
            newFilter.operator === oldFilter.operator &&
            newFilter.value.length === oldFilter.value.length
          ) {
            changed = false;
          }
        }
        if (changed) {
          this.allowSaveCard = true;
        }
      }
    }
    // check if the selected columns changed
    if (this.currentReportCardModel.reportColumns.length !== this.reportCardModel.reportColumns.length) {
      this.allowSaveCard = true;
    } else {
      for (const oldColumn of this.reportCardModel.reportColumns) {
        let changed: boolean = true;
        for (const newColumn of this.currentReportCardModel.reportColumns) {
          if (newColumn.code === oldColumn.code &&
            newColumn.dataType === oldColumn.dataType &&
            newColumn.name === oldColumn.name
          ) {
            changed = false;
          }
        }
        if (changed) {
          this.allowSaveCard = true;
        }
      }
    }
    // check if typeReport change
    if (this.reportCardModel.typeReport !== this.currentReportCardModel.typeReport) {
      this.allowSaveCard = true;
    }
    // check if size of the report change
    if (this.reportCardModel.cardHeightSize !== this.currentReportCardModel.cardHeightSize) {
      this.allowSaveCard = true;
    }
    if (this.reportCardModel.cardWidthSize !== this.currentReportCardModel.cardWidthSize) {
      this.allowSaveCard = true;
    }
  }

  public dimensionChanged(dimension: DimensionModel) {
    this.savedReportColumns = [];
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  @Watch('dates', { immediate: true, deep: true })
  private async onDatesChanged(newVal: any) {
    if (this.dates.length < 3) {
      return;
    }
    this.defaultRange = this.dates[2];
    this.checkAllowSaveCard();
  }

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChange(newVal: any) {
    if (!this.readyToGetReport) {
      return;
    }
    this.loading = true;
    this.readyToGetReport = false;
    this.dates = [];
    this.reportCardModel = new ReportCardModel();
    this.filterDimensions = new FilterDimensionList();
    this.filterColumns = new FilterColumnList();
    this.initFilterColumns = new FilterColumnList();
    this.reportColumns = [];
    this.savedReportColumns = [];
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.analyticsType = new AnalyticsTypeModel();
    this.pivotOption = '';
    await this.fullLoadData();
  }

  @Watch('initReportColumns', { immediate: true, deep: true })
  private onInitReportColumnsChanged(newVal: any) {
    if (newVal) {
      for (const newItem of this.initReportColumns) {
        let isExist: boolean = false;
        for (const oldItem of this.reportColumns) {
          if (oldItem.code === newItem.code) {
            isExist = true;
          }
        }
        if (!isExist) {
          this.reportColumns.push(newItem);
        }
      }
    }
  }

  @Watch('selectedReportColumns', { immediate: true, deep: true })
  private onSelectedReportColumnsChanged(newVal: any) {
    if (this.selectedReportColumns && this.selectedReportColumns.length > 0) {
      const columns: any = [];
      for (const c of this.selectedReportColumns) {
        columns.push(c);
      }
      this.allowSaveCard = true;
      this.savedReportColumns = columns;
    }
  }
  @Watch('analyticsType', { immediate: true, deep: true })
  private onAnalyticsTypeChanged(newVal: any, oldVal: any) {
    if (newVal && oldVal) {
      this.checkAllowSaveCard();
    }
  }

  @Watch('filterColumns', { immediate: true, deep: true })
  private onFilterColumnsChanged(newVal: any, oldVal: any) {
    if (newVal && oldVal) {
      this.checkAllowSaveCard();
    }
  }

  @Watch('dimension', { immediate: true, deep: true })
  private async onDimensionChanged(newVal: any) {
    if (this.dimension && this.dimension.code && this.readyToGetReport) {
      this.initReportParams = new ParamList();
      this.reportParams = new ParamList();
      await this.initAvailableParams();
      this.initParamFromDefaultValue();
    }
  }
}
