import { get, del, post, put } from '@/services/http';
import { EmailReceiverList } from "@/collections/email_receivers";
import { EmailReceiverModel } from '@/models/email_receiver';
import { EmailTemplateModel } from "@/models/email_template";
import { FunnelEventModel } from '@/models/funnelEvent';

export class ScheduleModel {
  public id: number;
  public title: string;
  public outputType: string = 'email'; //email, slack, google_sheets
  public destination: string; //emails (email), slack_channel_id,  (email,google_drive_folder_id) (google_sheets), google_sheets_link (exist_google_sheets),
  public fileFormat: string = 'csv'; //csv, pdf, google_sheets, existed_google_sheets, existed_google_new_sheets
  public interval: string = 'daily';
  public deliveryDate: any;
  public deliveryWeekday: any;
  public deliveryHour: any;
  public timezone: string = '';
  public emails!: string[];
  public profileId!: number;
  public options: any;
  public upcomingAt: string = '';
  public createdAt: string = '';
  public receivers: EmailReceiverList = new EmailReceiverList();
  public emailTemplate: EmailTemplateModel = new EmailTemplateModel();
  public emailTemplateId!: number;
  public emailReceiverIds!: number[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.title = data.title;
    this.outputType = data.outputType;
    this.destination = data.destination;
    this.fileFormat = data.fileFormat;
    this.interval = data.interval;
    this.timezone = data.timezone;
    this.deliveryDate = [];
    this.deliveryWeekday = [];
    this.deliveryHour = [];
    this.emails = data.emails || [];
    this.options = [];
    this.upcomingAt = '';
    this.createdAt = '';
    this.receivers = new EmailReceiverList();
    this.emailTemplate = new EmailTemplateModel();
  }

  public async fetch() {
    const res: any = await get(`/schedule/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    let destination: string = this.destination;
    if (this.outputType === 'email' && this.emails && this.emails.length > 0) {
      destination = this.emails.join(',');
    }
    const data = {
      title: this.title,
      outputType: this.outputType,
      destination: destination,
      fileFormat: this.fileFormat,
      interval: this.interval,
      timezone: this.timezone,
      deliveryDate: this.deliveryDate,
      deliveryWeekday: this.deliveryWeekday,
      deliveryHour: this.deliveryHour,
      profileId: this.profileId,
      options: this.options,
      emailReceiverIds: (this.receivers && this.receivers.items.length > 0)? this.receivers.items.map((receiver: EmailReceiverModel) => receiver.id): null,
      emailTemplateId: (this.emailTemplate? this.emailTemplate.id: null),
    };
    const res: any = await post('/schedule', data);
    this.mapData(res.data);
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'SCHEDULED_REPORT',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
  }

  public async update() {
    let destination: string = this.destination;
    if (this.outputType === 'email' && this.emails && this.emails.length > 0) {
      destination = this.emails.join(',');
    }
    const data = {
      id: this.id,
      title: this.title,
      outputType: this.outputType,
      destination: destination,
      fileFormat: this.fileFormat,
      interval: this.interval,
      timezone: this.timezone,
      deliveryDate: this.deliveryDate,
      deliveryWeekday: this.deliveryWeekday,
      deliveryHour: this.deliveryHour,
      profileId: this.profileId,
      options: this.options,
      emailReceiverIds: (this.receivers && this.receivers.items.length > 0)? this.receivers.items.map((receiver: EmailReceiverModel) => receiver.id): null,
      emailTemplateId: (this.emailTemplate? this.emailTemplate.id: null),
    }
    await put(`/schedule/${this.id}`, data);
  }

  public async remove() {
    await del(`/schedule/${this.id}`);
  }

  public mapData(data: any = {}) {
    const sortFunc = (a:any, b:any) => parseInt(a) - parseInt(b);
    this.id = data.id;
    this.title = data.title;
    this.outputType = data.outputType;
    this.fileFormat = data.fileFormat;
    this.interval = data.interval;
    this.timezone = data.timezone;
    this.deliveryDate = this.refineDate(data.deliveryDate.sort(sortFunc));
    this.deliveryWeekday = data.deliveryWeekday.sort(sortFunc);
    this.deliveryHour = this.refineHour(data.deliveryHour.sort(sortFunc));
    this.profileId = data.profileId;
    this.destination = data.destination;
    if (this.outputType === 'email') {
      let emails: string = data.destination + '';
      this.emails = emails.split(',');
    }
    if (data.options) {
      this.options = data.options;
    }
    this.upcomingAt = data.upcomingAt;

    if (data.createdAt) {
      const createdAt = new Date(data.createdAt);
      const pad = (n: number) => { return (n < 10) ? '0' + n : n };
      this.createdAt = `${createdAt.getFullYear()}-${pad(createdAt.getMonth() + 1)}-${pad(createdAt.getDate())}
            ${pad(createdAt.getHours())}:${pad(createdAt.getMinutes())}:${pad(createdAt.getSeconds())}`;
    }

    if (data.emailReceivers) {
      this.receivers.mapData(data.emailReceivers);
    }
    this.emailTemplateId = data.emailTemplateId;
    if (data.emailTemplate) {
      this.emailTemplate.mapData(data.emailTemplate);
    }
    this.emailReceiverIds = data.emailReceiverIds;
  }

  public async runTest() {
    return await get(`/schedule/${this.id}/execute`);
  }

  public getScheduleDate(day: string, dayOfMonth: string) {
    if (dayOfMonth && dayOfMonth !== '0') {
      return this.convertDayOfMonthToLabel(dayOfMonth);
    }
    return day;
  }

  public convertDayOfMonthToLabel(dayOfMonth: string) {
    switch (dayOfMonth) {
      case '-1':
        return 'Last day of Month';
      case '-2':
        return 'Day before end of Month';
      case '-3':
        return 'Two days before end of Month';
      default:
        return dayOfMonth;
    }
  }

  public refineHour(hours: any) {
    let strHours: string[] = [];
    for (const hour of hours) {
      if (Number(hour) < 10) {
        strHours.push('0' + Number(hour));
      } else {
        strHours.push('' + Number(hour));
      }
    }
    return strHours;
  }

  public refineDate(dates: any) {
    let strDates: string[] = [];
    for (const date of dates) {
      if (Number(date) < 10 && Number(date) > 0) {
        strDates.push('0' + Number(date));
      } else {
        strDates.push('' + Number(date));
      }
    }
    return strDates;
  }

  public mapReceivers(allReceivers: EmailReceiverList) {
    if (!this.emailReceiverIds || this.emailReceiverIds.length === 0) return;
    for (const receiver of allReceivers.items) {
      if (this.emailReceiverIds.includes(receiver.id)) {
        this.receivers.add(receiver);
      }
    }
  }
}
