
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { EventHandler } from '@/modules/events';
import { MyErrorHandler } from '@/modules/errors';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ProductSegmentTable from '@/components/ProductSegmentTable.vue';
import DateFilter from '@/components/DateFilter.vue';
import GroupByDimension from '@/components/GroupByDimension.vue';
import AnalyticsTypeFilter from '@/components/AnalyticsTypeFilter.vue';
import MeasureSelector from '@/components/MeasureSelector.vue';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { ProductSegmentModel } from '@/models/product_segment';
import dateFormat from 'dateformat';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import { StoreModel } from '@/models/store';
import { TemplateReportList } from '@/collections/template_reports';
import { MeasureList } from '@/collections/measures';
import FilterColumn from '@/components/FilterColumn.vue';
import ReportParam from '@/components/ReportParam.vue';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamList } from '@/collections/params';
import { FilterColumnModel } from '@/models/filter_column';
import { CustomReportList } from '@/collections/custom_reports';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { RuleModel } from '@/models/rule';
import { ParamModel } from '@/models/param';
import { SearchModel } from '@/models/search';
import ErrorMessage from '@/components/ErrorMessage.vue';
import { ComparisonDateModel } from '@/models/comparison_date';
import { EventBus } from '@/main';
import Alert from '@/components/Alert.vue';
import ActionButton from '@/components/ActionButton.vue';
import LoadingSlider from '@/components/LoadingSlider.vue';
import { DimensionColumnList } from '@/collections/dimension_columns';
import { MetricList } from '@/collections/metrics';
import { DimensionList } from '@/collections/dimensions';
import EtlStatusPage from '@/components/EtlStatusPage.vue';
import BaseComponent from "@/components/BaseComponent.vue";

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

@Component({
  components: {
    ProductSegmentTable,
    HeaderTitle,
    DateFilter,
    GroupByDimension,
    AnalyticsTypeFilter,
    FilterColumn,
    FeatureLockedNotify,
    ReportParam,
    ErrorMessage,
    Alert,
    ActionButton,
    LoadingSlider,
    MeasureSelector,
    EtlStatusPage,
  },
})
export default class ProductSegment extends Vue {
  public newProductSegmentName: string = '';
  public newProductSegmentDescription: string = '';

  public get isProcessingData() {
    if (this.productSegment &&
    this.productSegment.id &&
    this.productSegment.type === 'manual' &&
    this.productSegment.status === 'PROCESSING'
    ) {
      return true;
    }
    return false;
  }
  public get isManualSegment() {
    if (this.productSegment && this.productSegment.type === 'manual') {
      return true;
    }
    return false;
  }

  public get isAssistyAdmin() {
    if (this.store.shopName !== 'dariustest2.myshopify.com') {
      return false;
    }
    if (localStorage.getItem('canAddView') !== 'true') {
      return false;
    }
    if (localStorage.getItem('loggedInFromHades') !== 'true') {
      return false;
    }
    return true;
  }

  public get getDatatableSize() {
    if (this.isExpandPageMenu) {
      return 'datatable-show-action-menu';
    } else {
      return 'datatable-hide-action-menu';
    }
  }

  public get getPageMenuSize() {
    if (this.isExpandPageMenu) {
      return "page_menu_big";
    } else {
      return "page_menu_small";
    }
  }

  public get getPageSize() {
    if (this.showSetupMenu) {
      return 'page-show-setup';
    } else {
      return 'page-hide-setup';
    }
  }

  public get filterReportColumns() {
    let reportColumns: any = [];
    if (this.measure.filterDimensions.size() > 0) {
      for (const dim of this.measure.filterDimensions.toArray()) {
        reportColumns.push({
          name: dim.label ? dim.label : dim.name,
          code: dim.fieldCode,
          dataType: dim.dataType,
          luisMapping: dim.code,
        });
      }
    }
    for (const column of this.initReportFilterColumns) {
      reportColumns.push(column);
    }
    reportColumns = this.refineFilterColumns(reportColumns);
    return reportColumns;
  }

  public get pageTitle() {
    return this.measure.name;
  }

  public get dateRangeText() {
    return `${dateFormat(this.dates[0], 'mmm d, yyyy', true)} - ${dateFormat(
      this.dates[1],
      'mmm d, yyyy',
      true,
    )}`;
  }
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public measure: MeasureModel = new MeasureModel();
  public dimension: DimensionModel = new DimensionModel();
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public analyticsTypeCode: string = '';
  public newName: string = '';
  public readyToGetReport: boolean = false;
  public loading: boolean = false;
  public loadingSaveReport: boolean = false;
  public loadingSaveAs: boolean = false;
  public loadingRename: boolean = false;
  public loadingDelete: boolean = false;
  public dates: string[] = [];
  public defaultRange: string = '';
  public timeFilters = [
    'by_day',
    'by_week',
    'by_month',
    'by_quarter',
    'by_year',
  ];

  public filterDimensions: FilterDimensionList = new FilterDimensionList();

  public useDefaultConfig: boolean = false;
  public dialogSaveAs: boolean = false;
  public dialogRename: boolean = false;
  public dialogDelete: boolean = false;
  public dialogShareReport: boolean = false;
  public dialogSelectChartType: boolean = false;
  public linkCopied: boolean = false;
  public isInitFilterFromParam: boolean = false;
  public isInitReportParamFromParam: boolean = false;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public isLocked: string = 'AVAILABLE';
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public reportParams: ParamList = new ParamList();
  public initReportParams: ParamList = new ParamList();
  public reportColumns: any = [];
  public initReportColumns: any = [];
  public initReportFilterColumns: any = [];
  public selectedReportColumns: any = [];
  public savedReportColumns: any = [];
  public isResetFilterColumns: boolean = false;
  public customReports: CustomReportList = new CustomReportList();
  public pivotOption: string = '';
  public initPivotOption: string = '';
  public reportName: string = '';
  public columnFilterMenu: boolean = false;
  public groupByDimensionMenu: boolean = false;
  public filterDateRangeMenu: boolean = false;
  public reportGuideMenu: boolean = false;
  public columnSelectorMenu: boolean = false;
  public dateRangeMenu: boolean = false;
  public showActions: boolean = true;
  public breakpoint: string = 'md';
  public pageLayoutShowChart: boolean = true;
  public pageLayoutShowTable: boolean = true;
  public needRefreshTableData: boolean = false;
  public needRefreshMetricData: boolean = false;
  public dataError: MyErrorHandler = new MyErrorHandler('');
  public isCreated: boolean = false;
  public showSetupMenu: boolean = false;
  public setupModule: string = 'column';
  public chartType: string = 'BIG_NUMBER';
  public showChart: boolean = false;
  public hasChart: boolean = true;
  public userguides: any[] = [];
  public activeDimensions: DimensionColumnList = new DimensionColumnList();
  public availableParams: ParamList = new ParamList();
  public showPivot: boolean = false;
  public resourceLockedCode: string = 'paid_measures';
  public dialogEditFilterView: boolean = false;
  public dialogEditColumnView: boolean = false;
  public columnSelectorAction: string = 'column';
  public initPageOptions: Option = {};
  public pageOptions: Option = {};
  public calculatedMetrics: MetricList = new MetricList();
  public initCalculatedMetrics: MetricList = new MetricList();
  public isExpandPageMenu: boolean = true;
  public showSetting: boolean = true;
  public isFavoriteReport: boolean = false;
  public canAddFavorite: boolean = false;
  public availableMeasures: MeasureList = new MeasureList();
  public availableDimensions!: DimensionList;
  public initMeasure: MeasureModel = new MeasureModel();
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public newProductSegment: ProductSegmentModel = new ProductSegmentModel();
  public addProductToNewSegmentDialog: boolean = false;
  public createNewSegmentLoading: boolean = false;
  public addRemoveProductLoading: boolean = false;
  public isCreateNewSegment: boolean = false;
  public isAddRemoveProduct: boolean = false;
  public productIds: any = [];
  public removeProductDialog: boolean = false;
  public saveLoading: boolean = false;
  public editProductSegmentDialog: boolean = false;

  public async created() {
    this.loading = true;
    this.initSavedReportColumns();
    this.initAvailableMeasures();
    this.getPageMenuStatus();
    this.determineScreenSize();
    try {
      await this.fullLoadData();
    } catch (e: any) {
      //this.error(e);
      EventBus.$emit('show-snackbar', {
        message: 'Error loading data. Please try again',
        color: 'error',
      });
    }
    
    document.title = this.reportName;
    const searchModel: SearchModel = new SearchModel();
    EventBus.$on('reload-report-data', async () => {
      await this.retryLoadingData();
    });
    this.loading = false;
  }

  public showEditProductSegmentDialog() {
    this.editProductSegmentDialog = true;
  }
  public showCreateProductSegmentDialog() {
    EventBus.$emit('show-create-product-segment-dialog');
  }

  public saveProductToNewSegment(productIds: any = []) {
    this.addProductToNewSegmentDialog = true;
    this.productIds = productIds;
    this.newProductSegment = new ProductSegmentModel();
    this.isCreateNewSegment = false;
    this.isAddRemoveProduct = false;
  }

  

  public removeProductFromSegment(productIds: any = []) {
    this.productIds = productIds;
    this.removeProductDialog = true;
  }

  public async saveProductSegment() {
    if (!this.newProductSegmentName) {
      return;
    }
    this.productSegment.name = this.newProductSegmentName;
    if (this.newProductSegmentDescription) {
      this.productSegment.description = this.newProductSegmentDescription;
    }
    this.saveLoading = true;

    this.productSegment.pageOptions = this.pageOptions;

    if (this.productSegment.type === 'data_driven') {
      this.productSegment.measure = this.measure;
      this.productSegment.dimension = this.dimension;
      this.productSegment.analyticsType = this.analyticsType;
      this.productSegment.filters = this.filterColumns;
      this.productSegment.params = this.reportParams;
      this.productSegment.timerange = this.dates;
    }

    try {
      await this.productSegment.update();
      this.reportName = this.productSegment.name;
      EventBus.$emit('show-snackbar', {
        message: 'Product Segment updated successfully',
        color: 'success',
      });
    } catch (e: any) {
      this.saveLoading = false;
      EventBus.$emit('show-snackbar', {
        message: 'Error updating Product Segment. Please try again',
        color: 'error',
      });
    }
    this.saveLoading = false;
    this.editProductSegmentDialog = false;
  }

  public initAvailableMeasures() {
    const availableMeasures: any = [
      {
        name: 'Sales Overview',
        code: 'sales_overview',
      },
      {
        name: 'Sales and Inventory Matching',
        code: 'sales_inventory',
      },
      {
        name: 'Demand Forecasting',
        code: 'inventory_forecasting',
      },
      {
        name: 'Just-in-Time Replenishment',
        code: 'inventory_planning_date',
      },
      {
        name: 'Inventory On-hand',
        code: 'Actual Inventory Quantity',
      },
      {
        name: 'Weeks of Inventory Remaining',
        code: 'inventory_remaining_weeks',
      },
      {
        name: 'Days of Inventory Remaining',
        code: 'days_of_supply',
      },
      {
        name: 'Profitability',
        code: 'Profit Margin',
      },
      {
        name: 'Refund Rate',
        code: 'refund_order_rate',
      },
      {
        name: 'Refunded Amount',
        code: 'Refunded Amount',
      },
      {
        name: 'Inventory Movement',
        code: 'inventory_movement',
      },
      {
        name: 'Slow Stock Product',
        code: 'slow stock sku',
      },
      {
        name: 'Aging Stock Summary',
        code: 'aging_stock_summary',
      },
      {
        name: 'Aging Stock, Dead Stock',
        code: 'aging_stock',
      },
      {
        name: 'Inventory Value',
        code: 'Actual Inventory Cost',
      },
      {
        name: 'Inventory Turnover Rate',
        code: 'inventory_turrnover',
      },
      {
        name: 'Sell-through Rate',
        code: 'sell_through_rate',
      },
      {
        name: 'Inventory to Sales Ratio',
        code: 'inventory_to_sales_ratio',
      },
      {
        name: 'Stock Availability',
        code: 'stock_availability',
      },
      {
        name: 'Stockout Revenue Analysis',
        code: 'lost_revenue',
      },
      {
        name: 'Product Information',
        code: 'product_info',
      },
    ];
    const availableDimensions: any = [
      {
        name: 'Product',
        code: 'by_product',
      },
    ];
    this.availableMeasures = new MeasureList();
    for (const item of availableMeasures) {
      for (const measure of this.measures.items) {
        if (item.code === measure.code) {
          measure.name = item.name;
          this.availableMeasures.add(measure);
          break;
        }
      }
    }
  }

  public openAddMoreColumnDialogEvent() {
    EventBus.$emit('open-add-more-column-dialog-event');
  }

  public expandPageMenu() {
    this.isExpandPageMenu = !this.isExpandPageMenu;
    localStorage.setItem('isExpandPageMenu', this.isExpandPageMenu + '');
  }

  public getPageMenuStatus() {
    if (localStorage.getItem('isExpandPageMenu') === 'false') {
      this.isExpandPageMenu = false;
    } else {
      this.isExpandPageMenu = true;
    }
  }

  public async initActiveDimensions() {
    this.activeDimensions = new DimensionColumnList();
    await this.activeDimensions.fetch();
  }

  public async initAvailableParams() {
    if (!this.activeDimensions || this.activeDimensions.size() === 0) {
      await this.initActiveDimensions();
    }
    this.availableParams = new ParamList();
    if (this.measure.params && this.measure.params.size() > 0) {
      for (const item of this.measure.params.items) {
        this.availableParams.add(item);
      }
    }
    for (const dimension of this.activeDimensions.items) {
      if (dimension.luis_mapping === this.dimension.code) {
        if (dimension.params && dimension.params.size() > 0) {
          this.dimension.params = dimension.params;
          for (const item of dimension.params.items) {
            if (this.canAddAvailableParam(item)) {
              this.availableParams.add(item);
            }
          }
        }
      }
    }
  }

  public initParamFromDefaultValue() {
    if (this.initReportParams.size() === 0) {
      for (const item of this.availableParams.items) {
        if (item.value && item.value.length > 0) {
          const param = new ParamModel();
          param.NeedApiRequest = item.NeedApiRequest;
          param.code = item.code;
          param.listValues = item.listValues;
          param.name = item.name;
          param.sortOrder = item.sortOrder;
          param.value = item.value;
          param.valueType = item.valueType;
          param.dataType = item.dataType;
          param.default = item.default;
          this.initReportParams.add(param);
        }
      }
    }
  }

  public canAddAvailableParam(param: ParamModel) {
    for (const item of this.availableParams.items) {
      if (item.code === param.code) {
        return false;
      }
    }
    return true;
  }

  public openChat(openChatMessage: string) {
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    // this.$crisp.do('message:send', ['text', this.$t(`chat_with_us.${this.defaultMessage}`)]);
    this.$crisp.do('message:send', ['text', openChatMessage]);
  }

  public showExportDialog() {
    EventBus.$emit('show-download-dialog');
  }

  public getMenuItemStyle(hover: boolean = true) {
    let style: string = '';
    if (hover) {
      style = style + 'background-color: #d4d4d4;';
    }
    if (this.isExpandPageMenu) {
      style = style + 'padding: 0 16px;';
    } else {
      style = style + 'padding: 0;';
    }
    return style;
  }

  public error(error: any) {
    if (error instanceof MyErrorHandler) {
      this.dataError = error;
    } else {
      this.dataError = new MyErrorHandler('Unexpected Exception');
    }
  }

  public async retryLoadingData() {
    this.dataError = new MyErrorHandler('');
    this.needRefreshTableData = true;
  }

  public refreshTableDataCompleted() {
    this.needRefreshTableData = false;
  }

  public needRefreshTableDataEvent() {
    this.needRefreshTableData = true;
  }

  public changePageLayout(
    showChart: boolean = true,
    showTable: boolean = true,
  ) {
    this.pageLayoutShowChart = showChart;
    this.pageLayoutShowTable = showTable;
  }
  public determineScreenSize() {
    this.breakpoint = this.$vuetify.breakpoint.name;
    if (['xs', 'sm'].includes(this.breakpoint)) {
      this.showActions = false;
    }
  }

  public refineFilterColumns(reportColumns: any) {
    const duplicateColumns: any = {
      'Item[Item Category]': 'product_collections',
      'product_tag': 'product_tags',
      'order_tag': 'order_tags',
      'customer_tag': 'customer_tags',
    };

    for (const column of reportColumns) {
      if (Object.keys(duplicateColumns).includes(column.code)) {
        let index: number = 0;
        for (const column2 of reportColumns) {
          if (column2.code === duplicateColumns[column.code]) {
            reportColumns.splice(index, 1);
            break;
          }
          index++;
        }
      }
    }
    return reportColumns;
  }

  public getTranslatedReportName(reportName: string) {
    if (this.$te(`report_name.${reportName}`)) {
      return this.$t(`report_name.${reportName}`);
    } else {
      return reportName;
    }
  }

  public getDate(date: string) {
    return dateFormat(date, 'mmm d, yyyy');
  }

  public operatorName(value: string) {
    const operators: any = [
      { name: 'equal', value: 'eq' },
      { name: 'equal (case-insensitive)', value: 'eqi' },
      { name: 'not equal', value: 'ne' },
      { name: 'is lower than', value: 'lt' },
      { name: 'is lower than or equal to', value: 'lte' },
      { name: 'is greater than', value: 'gt' },
      { name: 'is greater than or equal to', value: 'gte' },
      { name: 'contains', value: 'contains' },
      { name: 'not contains', value: 'notContains' },
      { name: 'is blank', value: 'null' },
      { name: 'is not blank', value: 'notNull' },
    ];
    for (const item of operators) {
      if (item.value === value) {
        return item.name;
      }
    }
    return value;
  }

  public filterValuesToString(values: any) {
    if (!values) {
      return '';
    }
    return values.join(' or ');
  }

  public async initDefaultData() {
    if (this.$route.params.segment_id) {
      this.productSegment.id = Number(this.$route.params.segment_id);
      await this.productSegment.fetch();
      this.measure = this.productSegment.measure;
      this.dimension = this.productSegment.dimension;
      await this.defineMeasure();
      this.initMeasure = this.measure;
      for (const dimension of this.measure.dimensions.items) {
        if (dimension.code === this.dimension.code) {
          this.dimension = dimension;
          break;
        }
      }
      this.analyticsType = this.productSegment.analyticsType;
      if (this.productSegment.timerange.length > 2) {
        if (this.productSegment.timerange[2] !== 'custom') {
          this.defaultRange = this.productSegment.timerange[2];
          this.dates = [];
        } else {
          this.dates = this.productSegment.timerange;
          this.defaultRange =  this.productSegment.timerange[2];
          
        }
      }

      this.reportColumns = this.productSegment.reportColumns;
      this.filterColumns = this.productSegment.filters;
      this.initFilterColumns = this.productSegment.filters;
      this.reportParams = this.productSegment.params;
      this.initReportParams = this.productSegment.params;
      this.initPageOptions = this.productSegment.pageOptions;
      this.pageOptions = this.productSegment.pageOptions;
      this.reportName = this.productSegment.name;
    } else {
      if (!this.measure.code || this.measure.code === undefined) {
        this.measure = new MeasureModel();
        this.measure.code = 'product_info';
      }
      await this.defineMeasure();
      this.initMeasure = this.measure;
      this.reportName = 'Product Segment Builder';
      if (!this.dimension.code || this.dimension.code === undefined) {
        const dimensionCode = 'by_product';
        for (const dimension of this.measure.dimensions.items) {
          if (dimension.code === dimensionCode) {
            this.dimension = dimension;
            break;
          }
        }
      }
      if (!this.analyticsType.code || this.analyticsType.code === undefined) {
        const analyticType = 'top_high';
        this.analyticsType = new AnalyticsTypeModel({
          id: '0',
          name: analyticType,
          code: analyticType,
        });
      }
    }
  }

  public async fullLoadData() {
    await this.initDefaultData();
    await this.initAvailableParams();
    this.initParamFromDefaultValue();
    await this.setDefaultTimerange();
    this.newProductSegmentName = this.productSegment.name;
    this.newProductSegmentDescription = this.productSegment.description;
    this.readyToGetReport = true;
    this.sendMixpanelEvent();
  }

  public loadReportParamFromSavedParams(params: ParamList) {
    const newParams: ParamList = new ParamList();
    for (const param of params.items) {
      for (const item of this.availableParams.items) {
        if (param.code === item.code) {
          param.valueType = item.valueType;
          param.listValues = item.listValues;
          param.dataType = item.dataType;
          newParams.add(param);
        }
      }
    }
    this.reportParams = newParams;
  }

  public sendMixpanelEvent() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      if (this.productSegment.id) {
        eventHandler.track('View Product Segment', {
          segment_name: this.productSegment.name,
          segment_id: this.productSegment,
          dimension: this.dimension.code,
          measure: this.measure.code,
        });
      } else {
        eventHandler.track('Product Segment Builder', {
          dimension: this.dimension.code,
          measure: this.measure.code,
        });
      }
    } catch (e) {
      // ignore this catch
    }
  }

  public async defineMeasure() {
    for (const measure of this.measures.items) {
      if (measure.code === this.measure.code) {
        this.measure = measure;
        return;
      }
    }
    await this.measure.fetch();
  }

  public async setDefaultTimerange() {
    if (this.defaultRange) {
      return;
    }
    if (!this.measure.hasTimerange) {
      return;
    }

    if (this.dates && this.dates.length > 2) {
      this.defaultRange = 'custom';
      return;
    }
    const timeFilters = [
      'by_day',
      'by_week',
      'by_month',
      'by_quarter',
      'by_year',
    ];
    switch (this.dimension.code) {
      case 'by_week':
        this.defaultRange = '30d';
        break;
      case 'by_month':
        this.defaultRange = '6m';
        break;
      case 'by_quarter':
        this.defaultRange = '1y';
        break;
      case 'by_year':
        this.defaultRange = '3y';
        break;
      case 'by_day':
        this.defaultRange = '7d';
        break;
      default:
        this.defaultRange = '30d';
        break;
    }
  }

  public notResetColumnFilter(e: any) {
    this.isResetFilterColumns = false;
  }

  public changeDimension(dimension: DimensionModel) {
    this.dimension = dimension;
  }

  public canViewReport() {
    const validateValues: any = [
      {
        code: this.dimension.code,
      },
      {
        code: this.measure.code,
      },
      {
        code:
          'measure_' + this.measure.code + '_dimension_' + this.measure.code,
      },
    ];
    if (this.measure.measureTags && this.measure.measureTags.length > 0) {
      for (const tag of this.measure.measureTags) {
        if (
          tag.code === 'essential_inventory_optimize' ||
          tag.code === 'measure_essential_sales_analytics'
        ) {
          validateValues.push({
            code: tag.code,
            value: 'unlocked',
          });
        }
      }
    }
    const validateResult = this.store.validateRule(validateValues);
    this.resourceLockedCode = validateResult.resource;
    return validateResult.isValidate;
  }

  public assignInitReportFilterColumns(reportFilterColumns: any) {
    this.initReportFilterColumns = reportFilterColumns;
  }

  public initSavedReportColumns() {
    this.savedReportColumns = [{ name: 'Product Id', code: 'product_id' }];
  }
  public dimensionChanged(dimension: DimensionModel) {
    this.initSavedReportColumns();
  }

  public measureChanged(measure: MeasureModel) {
    //
  }

  public updateReportOption(pageOptions: any) {
    this.pageOptions = pageOptions;
  }

  public updateReportColumn(reportColumns: any) {
    this.initReportColumns = reportColumns;
    this.reportColumns = reportColumns;
    if (this.initReportColumns && this.initReportColumns.length > 0) {
      if (!this.isCreated) {
        this.isCreated = true;
      }
    }
  }

  public saveReportHeaders(reportHeaders: any) {
    // build new list of columns
    const columns: any = [];
    for (const header of reportHeaders) {
      for (const c of this.reportColumns) {
        if (header.value === c.code) {
          const column = {
            name: header.text,
            code: c.code,
            dataType: c.dataType,
            luisMapping: c.luisMapping,
          };

          columns.push(column);
        }
      }
    }
    this.selectedReportColumns = columns;
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  private rebuildSaveAsName() {
    let saveAsName = '';
    if (this.analyticsType.name) {
      saveAsName += this.analyticsType.name + ' ';
    }
    saveAsName += this.measure.name;
    if (this.dimension.name) {
      saveAsName += ' break-down by ' + this.dimension.name;
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChange(newVal: any) {
    if (!this.readyToGetReport) {
      return;
    }
    this.readyToGetReport = false;
    this.addProductToNewSegmentDialog = false;
    this.productSegment = new ProductSegmentModel();
    this.reportColumns = [];
    this.reportParams = new ParamList();
    this.initReportParams = new ParamList();
    this.dates = [];
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.filterColumns = new FilterColumnList();
    await this.fullLoadData();

  }

  @Watch('dimension', { immediate: true, deep: true })
  private async onDimensionChanged(newVal: any) {
    if (this.dimension && this.dimension.code && this.readyToGetReport) {
      this.initReportParams = new ParamList();
      this.reportParams = new ParamList();
      await this.initAvailableParams();
      this.initParamFromDefaultValue();
      this.sendMixpanelEvent();
    }
  }

  @Watch('measure', { immediate: true, deep: true })
  private async onMeasureChanged(newVal: any) {
    if (this.measure && this.measure.code && this.readyToGetReport) {
      this.needRefreshTableData = true;
      this.initReportParams = new ParamList();
      this.reportParams = new ParamList();
      await this.initAvailableParams();
      this.initParamFromDefaultValue();
      this.reportName = 'Product Segment - ' + this.measure.name;
      document.title = this.reportName;
      this.sendMixpanelEvent();
    }
  }
}
