
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get, post } from '@/services/http';
import Config from '../models/config';
import List from '../collections/list';
import HeaderTitle from '../components/HeaderTitle.vue';
import { StoreModel } from '@/models/store';
import { StoreConnectionModel } from '@/models/store_connection';
import { StoreConnectionList } from '@/collections/store_connections';
import { StoreConnectionRequestList } from '@/collections/store_connection_requests';
import { StoreConnectionPendingRequestList } from '@/collections/store_connection_pending_requests';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    StoreConnectionModel,
    StoreConnectionList,
    StoreConnectionRequestList,
    StoreConnectionPendingRequestList,
    Alert,
  },
})
export default class Setting extends Vue {
  @Prop({ default: () => new StoreConnectionModel() })
  public storeConnection!: StoreConnectionModel;
  public storeConnections: StoreConnectionList = new StoreConnectionList();
  public storeConnectionRequests: StoreConnectionRequestList = new StoreConnectionRequestList();
  public storeConnectionPendingRequests: StoreConnectionPendingRequestList = new StoreConnectionPendingRequestList();
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public config: Config = new Config();
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({default: null})
  public app!: any;
  public storeConnectedHeaders: any = [];
  public storeRequestHeaders: any = [];
  public dialogDelete: boolean = false;
  public loadingDelete: boolean = false;
  public deletingConnection: StoreConnectionModel = new StoreConnectionModel();
  public dialogAddStore: boolean = false;
  public loadingAddStore: boolean = false;
  public storeNames: string[] = [];
  public isLoading: boolean = false;
  public addStoreError: string = '';
  public addStoreSuccess: string = '';
  public deleteStoreError: string = '';
  public deleteStoreSuccess: string = '';
  public activateConnection: StoreConnectionModel = new StoreConnectionModel();
  public dialogAccept: boolean = false;
  public requestingConnection: StoreConnectionModel = new StoreConnectionModel();
  public messageError: string = '';
  public messageSuccess: string = '';
  public loadingRequest: boolean = false;
  public dialogReject: boolean = false;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isEnableMultipleStore: boolean = false;

  public async created() {
    if (this.store.validateRule([{
      code: 'multiple_shopify_store_analytics',
    }]).isValidate) {
      this.isEnableMultipleStore = true;
    }

    this.storeConnectedHeaders = [
      {
        text: 'Store Name',
        value: 'connectedStoreName',
        width: '',
      },
      {
        text: 'Status',
        value: 'status',
        width: '',
      },
      {
        text: 'Action',
        value: 'action',
        width: '',
        sortable: false,
      },
    ];
    this.storeRequestHeaders = [
      {
        text: 'Store Name',
        value: 'storeName',
        width: '',
      },
      {
        text: 'Status',
        value: 'status',
        width: '',
      },
      {
        text: 'Action',
        value: 'action',
        width: '',
        sortable: false,
      },
    ];
    this.loading = true;
    try {
      await this.storeConnections.fetch();
      await this.storeConnectionPendingRequests.fetch();
      this.error = false;
      this.success = true;
      this.loading = false;
    } catch (e: any) {
      this.loading = false;
      this.error = true;
      this.success = false;
      return;
    }
  }

  public async fullLoadData() {
    this.loading = true;
    await this.storeConnections.fetch();
    await this.storeConnectionPendingRequests.fetch();
    this.loading = false;
  }

  public async disconnectStore() {
    this.loadingDelete = true;
    try {
      await this.deletingConnection.remove();
      this.deletingConnection = new StoreConnectionModel();
      this.deleteStoreSuccess = 'Disconnect store successfully';
      this.loadingDelete = false;
      await this.fullLoadData();
      this.dialogDelete = false;
      this.deleteStoreSuccess = '';
    } catch (e: any) {
      this.loadingDelete = false;
      this.deleteStoreError = `There was an error: ${e}`;
    }
  }

  public async acceptRequest() {
    this.loadingRequest = true;
    try {
      await this.requestingConnection.accept();
      this.requestingConnection = new StoreConnectionModel();
      this.messageSuccess = 'Accept store connection successfully';
      this.loadingRequest = false;
      await this.fullLoadData();
      this.dialogAccept = false;
      this.messageSuccess = '';
    } catch (e: any) {
      this.loadingRequest = false;
      this.messageError = `There was an error: ${e}`;
    }
  }

  public async rejectRequest() {
    this.loadingRequest = true;
    try {
      await this.requestingConnection.reject();
      this.requestingConnection = new StoreConnectionModel();
      this.messageSuccess = 'Store connection has been rejected';
      this.loadingRequest = false;
      await this.fullLoadData();
      this.dialogReject = false;
      this.messageSuccess = '';
    } catch (e: any) {
      this.loadingRequest = false;
      this.messageError = `There was an error: ${e}`;
    }
  }

  public async addStoreConnections() {
    this.loadingAddStore = true;
    this.addStoreError = '';
    this.addStoreSuccess = '';
    if (this.storeNames.length === 0) {
      this.addStoreError = 'Please enter the store name';
      this.loadingAddStore = false;
      return;
    }
    try {
      for (const storeName of this.storeNames) {
        await this.storeConnection.request(storeName.replace(/\t|\r|\n/g, ''));
      }
      this.addStoreSuccess = 'Added more stores successfully';
      this.loadingAddStore = false;
      await this.fullLoadData();
      this.dialogAddStore = false;
      this.addStoreSuccess = '';
      this.storeNames = [];
    } catch (e: any) {
      this.loadingAddStore = false;
      this.addStoreError = `There was an error: ${e}`;
    }
  }

  public goActivateConnection() {
    const url: string = `https://admin.shopify.com/store/${this.activateConnection.connectedStoreName.replace('.myshopify.com', '')}/apps/assisty/config-multiple-stores`;
    window.open(url, '_blank', 'noreferrer');
  }

  public closeAddStoreDialog() {
    this.dialogAddStore = false;
    this.loadingAddStore = false;
    this.addStoreError = '';
    this.addStoreSuccess = '';
  }

  public remove(item: string) {
    this.storeNames.splice(this.storeNames.indexOf(item), 1);
    this.storeNames = [...this.storeNames];
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
