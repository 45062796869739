
import { StoreModel } from '@/models/store';
import { ReportModel } from '@/models/report';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { DimensionModel } from '@/models/dimension';
import { MeasureModel } from '@/models/measure';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamList } from '@/collections/params';
import { MetricList } from '@/collections/metrics';
import { ProductSegmentModel } from '@/models/product_segment';

@Component({
  components: {

  },
})
export default class DTotal extends Vue {
  public page: number = 1;
  public perPage: number = 0;
  @Prop({ default: () => [] })
  public headers!: any;
  public rows: any = [];
  @Prop({ default: () => [] })
  public initSelectedReportColumns!: any[];
  public selectedReportColumns: any = [];
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public messageColor: string = '';
  public message: string = 'Please selelect a dashboard to save the card';
  public loadingSlides: string[] = [
    'Connecting to analytic services',
    'Fetching data',
    'Building report',
    'Downloading data',
    'Generating card data',
  ];
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({ default: () => new DimensionModel() })
  public dimension!: DimensionModel;
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  @Prop({ default: () => [] })
  public filterTimerange!: string[];
  @Prop({ default: () => '30d' })
  public defaultRange!: string;
  public report: ReportModel = new ReportModel();
  public isLoading: boolean = false;
  public errorData: boolean = false;
  @Prop({ default: () => new FilterColumnList() })
  public filterColumns!: FilterColumnList;
  @Prop({ default: () => new ParamList() })
  public reportParams!: ParamList;
  @Prop({ default: false })
  public isResetFilterColumns!: boolean;
  @Prop({ default: '' })
  public pivotOption!: string;
  @Prop({default: () => new MetricList()})
  public calculatedMetrics!: MetricList;
  @Prop({default: () => new ProductSegmentModel()})
  public productSegment!: ProductSegmentModel;
  public async created() {
    await this.getData();
  }

  public async getData() {
    try {
      this.isLoading = true;
      this.report.measure = this.measure;
      this.report.dimension = this.dimension;
      this.report.analyticsType = this.analyticsType;
      this.report.filterTimerange = this.filterTimerange;
      this.report.filterColumns = this.filterColumns;
      this.report.reportParams = this.reportParams;
      this.report.typeReport = 'BIG_NUMBER';
      this.report.isDetail = false;
      this.report.isMetric = true;
      this.report.page = 1;
      this.report.perPage = 0;
      this.report.calculatedMetrics = this.calculatedMetrics;
      this.report.productSegment = this.productSegment;
      if (this.pivotOption && this.pivotOption !== 'undefined') {
        this.report.pivotOption = this.pivotOption;
      }
      await this.report.getReport();

      this.reloadTable();
      this.isLoading = false;
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        // this.$emit('error', error);
        this.errorData = true;
        this.isLoading = false;
      }
    }
  }

  public removeUnselectedColumn() {
    const report = new ReportModel();
    for (const column of this.report.columns) {
      if (this.isSelectedColumn(column.code)) {
        report.columns.push(column);
      }
    }
    this.report.columns = report.columns;
  }

  public generateSelectedReprotColumns() {
    if (!this.initSelectedReportColumns || this.initSelectedReportColumns.length === 0) {
      if (this.report.columns.length > 0) {
        this.selectedReportColumns = this.report.columns;
      }
    } else {
      this.selectedReportColumns = this.initSelectedReportColumns;
    }
  }

  public reloadTable() {
    this.generateSelectedReprotColumns();
    this.removeUnselectedColumn();
    this.rows = [];
    for (const index in this.report.rows) {
      if (this.report.rows[index]) {
        const res: any = {};
        for (const column of this.report.columns) {
          if (!this.isSelectedColumn(column.code)) {
            continue;
          }
          if (column.values[index]) {
            res[column.code] = column.values[index];
          } else {
            if (column.dataType === 'number'
              || column.dataType === 'decimal'
              || column.dataType === 'currency'
              || column.dataType === 'percent') {
              res[column.code] = '0';
            } else {
              res[column.code] = '-';
            }
          }
        }
        this.rows.push(res);
      }
    }
  }

  public isSelectedColumn(code: string) {
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }
}
